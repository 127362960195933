import _ from 'lodash';

const localeToSettingsMap = {
  "en-US": {
    isDefault: true,
    settings: {
  "_id": "4KRGLSrqTRY6s18UzHe1II",
  "_contentTypeId": "settingsGlobal",
  "_modifiedDate": "2024-10-08T22:02:32.138Z",
  "internalTitle": "CX Learning Hub Global Settings",
  "topNavigationLogo": {
    "title": "Dropbox Logo",
    "url": "//images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
    "size": 3707,
    "width": 324,
    "height": 64,
    "filename": "Dropbox.svg",
    "contentType": "image/svg+xml"
  },
  "mainNavigation": [
    {
      "internalTitle": "Supernav - Dropbox Help Center",
      "linkText": "Help center",
      "destinationType": "Manual text entry",
      "_id": "5pPOxJP1W9PaPBCnLHhe5x",
      "_contentTypeId": "elementLink",
      "href": "https://help.dropbox.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Community",
      "linkText": "Community",
      "destinationType": "Manual text entry",
      "_id": "7aVH719lug9lYEJzIntabM",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropboxforum.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox learn",
      "linkText": "Dropbox learn",
      "destinationType": "Content reference",
      "_id": "2EPydjcpXLep5NbdcRq3ca",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/home",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Contact us",
      "linkText": "Contact support",
      "destinationType": "Manual text entry",
      "_id": "5v3vwPdKulQu6xhA6csIT8",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropbox.com/support",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    }
  ],
  "secondaryNavigation": [
    {
      "internalTitle": "Secondary Nav - Self-guided learning",
      "linkText": "Self-guided learning",
      "destinationType": "Content reference",
      "_id": "oMqs4whMJuBjhZsbMv0KA",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/self-guided-learning",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Virtual training",
      "linkText": "Virtual training",
      "destinationType": "Content reference",
      "_id": "31NB5ThxIWIxIju5dMFYC5",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/virtual-training",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Videos Landing Page",
      "linkText": "Video library",
      "destinationType": "Content reference",
      "_id": "5y8ArqrhCZ6DoCCGxaumhI",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/video-library",
      "target": null,
      "isModal": false,
      "download": false
    }
  ],
  "footerNavigation": [
    {
      "_id": "1M79uBMmyuD9Iw6fuOdVEJ",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:11:07.049Z",
      "internalTitle": "Dropbox",
      "links": [
        {
          "internalTitle": "Footer Link - Desktop app",
          "linkText": "Desktop app",
          "destinationType": "Manual text entry",
          "_id": "3SKeLjF7MWEq2FqaRwnmiK",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/install?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Mobile apps",
          "linkText": "Mobile apps",
          "destinationType": "Manual text entry",
          "_id": "62ymoNSBbWiYinBkFO0cGj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/mobile?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Plans",
          "linkText": "Plans",
          "destinationType": "Manual text entry",
          "_id": "3Fdgsos4Pm77l2C4hKm2AC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plans?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Security",
          "linkText": "Security",
          "destinationType": "Manual text entry",
          "_id": "2YZlyLOZLw2lauy2OWiuB2",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/security?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Features",
          "linkText": "Features",
          "destinationType": "Manual text entry",
          "_id": "wUuQPtOLYz96QOSvtgLTh",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/features?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "6LXqBrZEH3p2eDbBpiPFlm",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:40.613Z",
      "internalTitle": "Products",
      "links": [
        {
          "internalTitle": "Footer Links - Plus",
          "linkText": "Plus",
          "destinationType": "Manual text entry",
          "_id": "4nThAv7YjSuKC91UBK1AoR",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plus?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links -  Professional",
          "linkText": "Professional",
          "destinationType": "Manual text entry",
          "_id": "5YtdEKxrCJgHqmmDvwgnQI",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/pro?trigger=_footer",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Business",
          "linkText": "Business",
          "destinationType": "Manual text entry",
          "_id": "5L4PFaY36Icmt95mUTgjOC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/business?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Enterprise",
          "linkText": "Enterprise",
          "destinationType": "Manual text entry",
          "_id": "4KJcgnikVfGiWIaFiuiRIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/enterprise?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "5xRp5jjLsl92vmGL0qJfVb",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:03:26.918Z",
      "internalTitle": "Company",
      "links": [
        {
          "internalTitle": "Footer Link - About us",
          "linkText": "About us",
          "destinationType": "Manual text entry",
          "_id": "6XnBD8OrJfQZ4u3ZSc6ysr",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/about",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Jobs",
          "linkText": "Jobs",
          "destinationType": "Manual text entry",
          "_id": "1nmf2X1QaLv7zS1PIyfqlE",
          "_contentTypeId": "elementLink",
          "href": "https://jobs.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Press",
          "linkText": "Press",
          "destinationType": "Manual text entry",
          "_id": "5iMgmL64RXCXtz8iRkrIIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/news?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Investor relations",
          "linkText": "Investor relations",
          "destinationType": "Manual text entry",
          "_id": "73WTKUyXSmqc5aP1OfisZ9",
          "_contentTypeId": "elementLink",
          "href": "https://investors.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Blog",
          "linkText": "Blog",
          "destinationType": "Manual text entry",
          "_id": "4mTcqsVCIHytbISiyJordW",
          "_contentTypeId": "elementLink",
          "href": "https://blog.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "1TnZWWGdwo9BEkEKgcIGpA",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:07:49.392Z",
      "internalTitle": "Support",
      "links": [
        {
          "internalTitle": "Supernav - Dropbox Help Center",
          "linkText": "Help center",
          "destinationType": "Manual text entry",
          "_id": "5pPOxJP1W9PaPBCnLHhe5x",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Supernav - Dropbox Contact us",
          "linkText": "Contact support",
          "destinationType": "Manual text entry",
          "_id": "5v3vwPdKulQu6xhA6csIT8",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/support",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Cookie Policy",
          "linkText": "Cookie policy",
          "destinationType": "Manual text entry",
          "_id": "3akG8gNhd73ZlRaEYSCvUD",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/security/cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Manage Cookies",
          "linkText": "Manage cookies",
          "destinationType": "Manual text entry",
          "_id": "65mwIIQg9xKeyKiICuy9DJ",
          "_contentTypeId": "elementLink",
          "href": "#manage-cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Privacy & terms",
          "linkText": "Privacy & terms",
          "destinationType": "Manual text entry",
          "_id": "30MJJnJ2DIcvhYL2wsg0FB",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/privacy?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Sitemap",
          "linkText": "Sitemap",
          "destinationType": "Manual text entry",
          "_id": "37byNy2Pux5TAcdSJm6g4f",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/sitemap?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "28o2jcc5DrafqCYZ9icYU7",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:42.647Z",
      "internalTitle": "Community",
      "links": [
        {
          "internalTitle": "Footer Links - Developers",
          "linkText": "Developers",
          "destinationType": "Manual text entry",
          "_id": "2aFb3GcSJS0YIEU3aKbLzj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/developers?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Referrals",
          "linkText": "Referrals",
          "destinationType": "Manual text entry",
          "_id": "4YsKqwQfAG4Ds0a0yeo9wp",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/referrals?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Forum",
          "linkText": "Forum",
          "destinationType": "Manual text entry",
          "_id": "4IGtm6twVWzfoE07kHlucc",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropboxforum.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    }
  ],
  "cookieBarBody": "We use cookies so that Dropbox works for you. By using our website, you agree to our use of cookies.",
  "cookieBarCta": {
    "internalTitle": "Link Text - Cookie bar",
    "linkText": "Learn more",
    "destinationType": "Manual text entry",
    "_id": "1tY3PGTzdIHKRi4pDNbmSM",
    "_contentTypeId": "elementLink",
    "href": "https://help.dropbox.com/accounts-billing/security/cookies",
    "as": null,
    "target": "_blank",
    "isModal": false,
    "download": false
  },
  "cookieBarEnabled": true,
  "alertBarCta": null,
  "virtualTrainingsEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Nothing to see yet—register for a ",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "H59akjvvDv1tG26hXdjcK",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/virtual-training",
                "_modifiedDate": "2025-03-18T22:08:03.392Z",
                "internalTitle": "L2 - Landing page - Virtual training",
                "name": "Dropbox virtual training",
                "slug": "virtual-training",
                "introHero": {
                  "_id": "5Hy71DzB4jMPIMzXg2PFmC",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:26:18.543Z",
                  "internalTitle": "Hero - Virtual training",
                  "headerText": "Dropbox virtual training",
                  "summaryText": "Get free online Dropbox training with a live instructor. From Dropbox fundamentals to business training. Learn new skills through interactive sessions in a virtual classroom setting.",
                  "image": {
                    "title": "Learning Live Banner image",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6P5oPiTz2s9uchKdCXrtbj/1b95e452335e29c097137e710ade079c/Learning_Live_Banner_image.svg",
                    "size": 23584,
                    "width": 405,
                    "height": 310,
                    "filename": "Learning Live Banner image.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "2qm2M5L9rHtM93SagsoJ0P",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:26:32.733Z",
                    "internalTitle": "All training - Virtual training - Cards",
                    "title": "Live virtual training live sessions ",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Dropbox live virtual business training | Dropbox learn"
                  },
                  "robots": {
                    "name": "robots",
                    "value": "index,follow"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "Get free, interactive Dropbox training with a live virtual instructor, including admin and business team training. Register for a course today."
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "virtual training session",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": ".",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "completedCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Courses you’ve completed will appear here.",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "inProgressCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Nothing to see yet—start a ",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "75pzFP9abHFb2hlQycUS86",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/self-guided-learning",
                "_modifiedDate": "2025-03-18T22:08:04.576Z",
                "internalTitle": "L2 - Landing page - Self-guided learnings",
                "name": "Self-guided learning",
                "slug": "self-guided-learning",
                "introHero": {
                  "_id": "6pQlpoBglvzGW38DBWvU5W",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:24:30.062Z",
                  "internalTitle": "Hero - Self-guided learning",
                  "headerText": "Self-guided learning",
                  "summaryText": "Learn how to use Dropbox with courses designed to fit around your busy schedule.",
                  "image": {
                    "title": "Self-Guided Learning Illustration",
                    "description": "Book illustration for Dropbox's self-guided learning courses",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6ODZb6bllZrucIYtiLqFkU/f844e955cabc76e34e249d9c18e43d62/self-guided-learning.svg",
                    "size": 42902,
                    "width": 405,
                    "height": 310,
                    "filename": "self-guided-learning.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "6MGcQfM5kOFGpBqiOmSkKy",
                    "_contentTypeId": "moduleFeaturedCourse",
                    "_modifiedDate": "2025-03-18T22:24:36.327Z",
                    "internalTitle": "Featured - Self-guided learning",
                    "title": "Featured course",
                    "course": null
                  },
                  {
                    "_id": "4JIWsH681XIFek4zmBB6Ix",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:25:12.491Z",
                    "internalTitle": "All courses - Self-guided learning - Cards",
                    "title": "All courses",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Dropbox self-guided training courses | Dropbox learn"
                  },
                  "robots": {
                    "name": "robots",
                    "value": "index,follow"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "Self-paced Dropbox training courses, available for free and on-demand. Learn how to use your Dropbox account at your own pace. Sign up today."
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "self-guided learning course",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": ".",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "l18nLookup": {
    "textHr": "hr",
    "textHrs": "hrs",
    "textMin": "min",
    "textHour": "hour",
    "textLess": "Less",
    "textMins": "mins",
    "textMore": "More",
    "textRead": "Read",
    "labelTags": "Tags",
    "textClear": "Clear",
    "textHours": "hours",
    "textMinute": "minute",
    "ctaTextBack": "Back",
    "ctaTextNext": "Next",
    "joinUrlText": "Join Session",
    "readingTime": "minute read",
    "textMinutes": "minutes",
    "textSeeMore": "View more",
    "textViewAll": "View All",
    "searchHeader": "Search",
    "textComplete": "Complete",
    "textError404": "Error (404)",
    "textFilterBy": "Filter By",
    "ctaTextLogout": "Sign out",
    "ctaTextSignIn": "Sign in",
    "labelSubjects": "Subjects",
    "textNextTopic": "Next module",
    "ctaTextProfile": "Profile",
    "ctaTextVirtual": "View session",
    "headerDuration": "Duration",
    "languageNameDe": "Deutsch",
    "languageNameEs": "Español",
    "languageNameFr": "Français",
    "languageNameIt": "Italiano",
    "languageNameJa": "日本語",
    "ctaTextRegister": "Register now",
    "labelCategories": "Categories",
    "searchNoResults": "No results for {{search}}",
    "textStartCourse": "Start course",
    "textWebinarFull": "This webinar is full, please try a different time or day.",
    "accountLabelLast": "Last",
    "accountLabelName": "Name",
    "ctaCreateAccount": "Create account",
    "languageNameEnUs": "English",
    "languageNamePtBr": "Português",
    "searchBreadcrumb": "Search results",
    "searchFilterRole": "Role",
    "textErrorUnknown": "Something went wrong",
    "textResumeCourse": "Resume course",
    "accountLabelEmail": "Email",
    "ctaTextReschedule": "Reschedule",
    "ctaTextSelfGuided": "View modules",
    "ctaTextViewCourse": "View course",
    "formLabelAttended": "Attended",
    "headerAccountInfo": "Account info",
    "textErrorPageHome": "Home",
    "textPreviousTopic": "Previous module",
    "ctaTextCancelClass": "Cancel this class",
    "formInputLabelDate": "Date",
    "headerPersonalInfo": "Personal info",
    "labelSubcategories": "Subcategories",
    "searchResultStatus": "Results {{firstResult}}-{{lastResult}} of {{numResults}} for {{search}}",
    "textBreadcrumbRoot": "Home",
    "textCourseProgress": "Course progress",
    "formInputLabelEmail": "Email",
    "globalTextCopyright": "Copyright text",
    "textDropdownDefault": "Times are set to your time zone",
    "textErrorPageSignIn": "Sign in",
    "textTableOfContents": "Modules",
    "canceledRegistration": "Registration canceled successfully",
    "formInputLabelCourse": "Course",
    "formInputLabelStatus": "Status",
    "searchFilterLanguage": "Language",
    "searchResultsPerPage": "Results per page",
    "textSigninModalIntro": "Sign in to save your progress or continue scrolling",
    "topicCountTextPlural": "Topics",
    "topicCountTextSingle": "Topic",
    "headerSuggestedTopics": "Suggested modules",
    "searchFilterOptionSgc": "Self-paced course",
    "searchResultTypeTopic": "Module",
    "searchResultTypeVideo": "Video",
    "textAlreadyRegistered": "You have already registered for this webinar.",
    "textYouMustLogInFirst": "You must sign in first",
    "formInputLabelAttendee": "Attendee",
    "formInputLabelLastName": "Last name",
    "formInputLabelOverview": "Overview",
    "headerEventDescription": "Session description",
    "headerProfileCompleted": "Completed courses",
    "searchFilterOptionLive": "Live session",
    "searchFilterOptionTeam": "Team member",
    "textCourseExpectations": "What to expect:",
    "textLearningObjectives": "What you’ll learn",
    "formInputLabelFirstName": "First name",
    "headerProfileInProgress": "Courses in progress",
    "pageVideoCategoriesSync": "Sync",
    "searchFilterContentType": "Content type",
    "searchFilterOptionAdmin": "Admin",
    "searchFilterOptionTopic": "Module",
    "searchPageTitleMetadata": "Search | Dropbox Learn",
    "textErrorPageHelpCenter": "Help center",
    "formInputLabelSelectDate": "Select a date",
    "formInputLabelSelectTime": "Select a time",
    "headerProfileLiveCourses": "Upcoming courses",
    "pageVideoCategoriesShare": "Share",
    "formInputLabelSessionLink": "Session link",
    "textErrorPageHelpfulLinks": "Here are some helpful links:",
    "textErrorPagePageNotFound": "We can't find the page you're looking for",
    "textRegistrationCompleted": "You’re registered",
    "breadcrumbTextRegistration": "Registration",
    "formInputLabelPickLanguage": "Select your language",
    "pageVideoCategoriesWebinar": "Webinar",
    "searchPageKeywordsMetadata": "search",
    "searchResultTypeSelfGuided": "Course",
    "ctaTextCompleteRegistration": "Complete your registration",
    "headerEnterRegistrationInfo": "Enter your registration info",
    "headerRegisteredLiveCourses": "Registered virtual trainings",
    "pageVideoCategoriesInstalls": "Installs",
    "pageVideoCategoriesOrganize": "Organize",
    "pageVideoCategoriesProducts": "Products",
    "pageVideoCategoriesSecurity": "Security",
    "searchFilterOptionRoleAdmin": "Admin",
    "searchResultTypeLiveSession": "Live session",
    "headerRegistrationSuccessful": "You’ve successfully registered",
    "headerSearchInputPlaceholder": "Search",
    "pageVideoSubcategoriesDelete": "Delete",
    "pageVideoSubcategoriesReplay": "Replay",
    "pageVideoSubcategoriesRewind": "Rewind",
    "pageVideoSubcategoriesSignIn": "Sign in",
    "searchFilterOptionIndividual": "Individual",
    "formInputLabelCourseCompleted": "Course completed",
    "pageVideoSubcategoriesCapture": "Capture",
    "pageVideoSubcategoriesRestore": "Restore",
    "searchPageDescriptionMetadata": "You can search for modules or information to find out more about our courses. Find the right Dropbox training or self-guided learning for your needs.",
    "pageVideoCategoriesViewAndEdit": "View and edit",
    "pageVideoSubcategoriesTransfer": "Transfer",
    "searchFilterOptionRoleHelpDesk": "Help Desk",
    "textCalendarShortWeekdayFriday": "Fri",
    "textCalendarShortWeekdayMonday": "Mon",
    "textCalendarShortWeekdaySunday": "Sun",
    "pageVideoSubcategoriesMobileApp": "Mobile app",
    "textCalendarShortWeekdayTuesday": "Tue",
    "textEmailConfirmationWillBeSent": "Check your inbox for an email confirmation.",
    "headerLiveCourseHighlightsHeader": "You’ll learn how to:",
    "pageVideoCategoriesAccountAccess": "Account access",
    "pageVideoSubcategoriesTeamAccess": "Team access",
    "searchFilterOptionRoleTeamMember": "Team Member",
    "textCalendarShortWeekdaySaturday": "Sat",
    "textCalendarShortWeekdayThursday": "Thu",
    "formInputLabelGeneralRegistration": "General registration",
    "pageVideoSubcategoriesTeamFolders": "Team folders",
    "textCalendarShortWeekdayWednesday": "Wed",
    "formInputLabelGlobalSelectLanguage": "Select a language",
    "formInputLabelSelectDateBreadcrumb": "Select date",
    "pageVideoCategoriesAccountSettings": "Account settings",
    "pageVideoCategoriesCreateAndUpload": "Create and upload",
    "pageVideoSubcategoriesTeamSecurity": "Team security",
    "searchFilterOptionRoleFundamentals": "Fundamentals",
    "pageVideoCategoriesDeleteAndRestore": "Delete and restore",
    "pageCourseTopicTableOfContentsHeading": "Sections in this article",
    "pageVideoSubcategoriesDropboxPolicies": "Dropbox policies",
    "pageVideoSubcategoriesCommentingOnFiles": "Commenting on files",
    "pageVideoSubcategoriesSharingPermissions": "Sharing permissions",
    "pageVideoSubcategoriesDropboxApplications": "Dropbox applications",
    "pageVideoSubcategoriesSearchingYourAccount": "Searching your account",
    "pageVideoSubcategoriesManagingAccountAccess": "Managing account access",
    "pageVideoSubcategoriesSharingFilesAndFolders": "Sharing files and folders",
    "pageVideoSubcategoriesCreatingFilesAndFolders": "Creating files and folders"
  },
  "copyrightText": "Dropbox 2021",
  "seo": {
    "title": {
      "name": "title",
      "value": "Dropbox learn"
    },
    "keywords": {
      "name": "keywords",
      "value": ""
    },
    "og:image": {
      "name": "og:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "og:title": {
      "name": "og:title",
      "value": "Dropbox learn"
    },
    "canonical": {
      "name": "canonical",
      "value": ""
    },
    "description": {
      "name": "description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:image": {
      "name": "twitter:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "twitter:title": {
      "name": "twitter:title",
      "value": "Dropbox learn"
    },
    "og:description": {
      "name": "og:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:description": {
      "name": "twitter:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    }
  },
  "seoOrganizationsSchema": {
    "url": "http://www.dropbox.com",
    "@type": "Organization",
    "@context": "http://schema.org",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": "TollFree"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "contactType": "customer service"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": [
          "HearingImpairedSupported",
          "TollFree"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "technical support",
        "contactOption": "TollFree",
        "availableLanguage": [
          "English",
          "French"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "bill payment",
        "contactOption": "TollFree"
      }
    ]
  }
}
  },
  "fr": {
    isDefault: false,
    settings: {
  "_id": "4KRGLSrqTRY6s18UzHe1II",
  "_contentTypeId": "settingsGlobal",
  "_modifiedDate": "2024-10-08T22:02:32.138Z",
  "internalTitle": "CX Learning Hub Global Settings",
  "topNavigationLogo": {
    "title": "Dropbox Logo",
    "url": "//images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
    "size": 3707,
    "width": 324,
    "height": 64,
    "filename": "Dropbox.svg",
    "contentType": "image/svg+xml"
  },
  "mainNavigation": [
    {
      "internalTitle": "Supernav - Dropbox Help Center",
      "linkText": "Centre d’assistance",
      "destinationType": "Manual text entry",
      "_id": "5pPOxJP1W9PaPBCnLHhe5x",
      "_contentTypeId": "elementLink",
      "href": "https://help.dropbox.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Community",
      "linkText": "Communauté",
      "destinationType": "Manual text entry",
      "_id": "7aVH719lug9lYEJzIntabM",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropboxforum.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox learn",
      "linkText": "Découvrir Dropbox",
      "destinationType": "Content reference",
      "_id": "2EPydjcpXLep5NbdcRq3ca",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/home",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Contact us",
      "linkText": "Contacter l’assistance",
      "destinationType": "Manual text entry",
      "_id": "5v3vwPdKulQu6xhA6csIT8",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropbox.com/support",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    }
  ],
  "secondaryNavigation": [
    {
      "internalTitle": "Secondary Nav - Self-guided learning",
      "linkText": "Autoformation",
      "destinationType": "Content reference",
      "_id": "oMqs4whMJuBjhZsbMv0KA",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/self-guided-learning",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Virtual training",
      "linkText": "Formations virtuelles",
      "destinationType": "Content reference",
      "_id": "31NB5ThxIWIxIju5dMFYC5",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/virtual-training",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Videos Landing Page",
      "linkText": "Vidéos",
      "destinationType": "Content reference",
      "_id": "5y8ArqrhCZ6DoCCGxaumhI",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/video-library",
      "target": null,
      "isModal": false,
      "download": false
    }
  ],
  "footerNavigation": [
    {
      "_id": "1M79uBMmyuD9Iw6fuOdVEJ",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:11:07.049Z",
      "internalTitle": "Dropbox",
      "links": [
        {
          "internalTitle": "Footer Link - Desktop app",
          "linkText": "Application de bureau",
          "destinationType": "Manual text entry",
          "_id": "3SKeLjF7MWEq2FqaRwnmiK",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/install?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Mobile apps",
          "linkText": "Applications mobiles",
          "destinationType": "Manual text entry",
          "_id": "62ymoNSBbWiYinBkFO0cGj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/mobile?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Plans",
          "linkText": "Forfaits",
          "destinationType": "Manual text entry",
          "_id": "3Fdgsos4Pm77l2C4hKm2AC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plans?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Security",
          "linkText": "Sécurité",
          "destinationType": "Manual text entry",
          "_id": "2YZlyLOZLw2lauy2OWiuB2",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/security?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Features",
          "linkText": "Fonctionnalités",
          "destinationType": "Manual text entry",
          "_id": "wUuQPtOLYz96QOSvtgLTh",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/features?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "6LXqBrZEH3p2eDbBpiPFlm",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:40.613Z",
      "internalTitle": "Produits",
      "links": [
        {
          "internalTitle": "Footer Links - Plus",
          "linkText": "Plus",
          "destinationType": "Manual text entry",
          "_id": "4nThAv7YjSuKC91UBK1AoR",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plus?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links -  Professional",
          "linkText": "Professional",
          "destinationType": "Manual text entry",
          "_id": "5YtdEKxrCJgHqmmDvwgnQI",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/pro?trigger=_footer",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Business",
          "linkText": "Business",
          "destinationType": "Manual text entry",
          "_id": "5L4PFaY36Icmt95mUTgjOC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/business?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Enterprise",
          "linkText": "Enterprise",
          "destinationType": "Manual text entry",
          "_id": "4KJcgnikVfGiWIaFiuiRIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/enterprise?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "5xRp5jjLsl92vmGL0qJfVb",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:03:26.918Z",
      "internalTitle": "Entreprise :",
      "links": [
        {
          "internalTitle": "Footer Link - About us",
          "linkText": "À propos de Dropbox",
          "destinationType": "Manual text entry",
          "_id": "6XnBD8OrJfQZ4u3ZSc6ysr",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/about",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Jobs",
          "linkText": "Recrutement",
          "destinationType": "Manual text entry",
          "_id": "1nmf2X1QaLv7zS1PIyfqlE",
          "_contentTypeId": "elementLink",
          "href": "https://jobs.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Press",
          "linkText": "Presse",
          "destinationType": "Manual text entry",
          "_id": "5iMgmL64RXCXtz8iRkrIIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/news?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Investor relations",
          "linkText": "Relations avec les investisseurs",
          "destinationType": "Manual text entry",
          "_id": "73WTKUyXSmqc5aP1OfisZ9",
          "_contentTypeId": "elementLink",
          "href": "https://investors.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Blog",
          "linkText": "Blog",
          "destinationType": "Manual text entry",
          "_id": "4mTcqsVCIHytbISiyJordW",
          "_contentTypeId": "elementLink",
          "href": "https://blog.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "1TnZWWGdwo9BEkEKgcIGpA",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:07:49.392Z",
      "internalTitle": "Assistance",
      "links": [
        {
          "internalTitle": "Supernav - Dropbox Help Center",
          "linkText": "Centre d’assistance",
          "destinationType": "Manual text entry",
          "_id": "5pPOxJP1W9PaPBCnLHhe5x",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Supernav - Dropbox Contact us",
          "linkText": "Contacter l’assistance",
          "destinationType": "Manual text entry",
          "_id": "5v3vwPdKulQu6xhA6csIT8",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/support",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Cookie Policy",
          "linkText": "Politique en matière de cookies",
          "destinationType": "Manual text entry",
          "_id": "3akG8gNhd73ZlRaEYSCvUD",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/security/cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Manage Cookies",
          "linkText": "Gérer les cookies",
          "destinationType": "Manual text entry",
          "_id": "65mwIIQg9xKeyKiICuy9DJ",
          "_contentTypeId": "elementLink",
          "href": "#manage-cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Privacy & terms",
          "linkText": "Confidentialité et conditions",
          "destinationType": "Manual text entry",
          "_id": "30MJJnJ2DIcvhYL2wsg0FB",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/privacy?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Sitemap",
          "linkText": "Plan du site",
          "destinationType": "Manual text entry",
          "_id": "37byNy2Pux5TAcdSJm6g4f",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/sitemap?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "28o2jcc5DrafqCYZ9icYU7",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:42.647Z",
      "internalTitle": "Communauté",
      "links": [
        {
          "internalTitle": "Footer Links - Developers",
          "linkText": "développeurs",
          "destinationType": "Manual text entry",
          "_id": "2aFb3GcSJS0YIEU3aKbLzj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/developers?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Referrals",
          "linkText": "Parrainages",
          "destinationType": "Manual text entry",
          "_id": "4YsKqwQfAG4Ds0a0yeo9wp",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/referrals?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Forum",
          "linkText": "Forum",
          "destinationType": "Manual text entry",
          "_id": "4IGtm6twVWzfoE07kHlucc",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropboxforum.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    }
  ],
  "cookieBarBody": "Nous utilisons des cookies afin de vous permettre d’utiliser Dropbox. En utilisant notre site, vous acceptez notre utilisation des cookies.",
  "cookieBarCta": {
    "internalTitle": "Link Text - Cookie bar",
    "linkText": "En savoir plus",
    "destinationType": "Manual text entry",
    "_id": "1tY3PGTzdIHKRi4pDNbmSM",
    "_contentTypeId": "elementLink",
    "href": "https://help.dropbox.com/accounts-billing/security/cookies",
    "as": null,
    "target": "_blank",
    "isModal": false,
    "download": false
  },
  "cookieBarEnabled": true,
  "alertBarCta": null,
  "virtualTrainingsEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Rien à afficher pour l’instant. Inscrivez-vous à une ",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "H59akjvvDv1tG26hXdjcK",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/virtual-training",
                "_modifiedDate": "2025-03-18T22:08:03.392Z",
                "internalTitle": "L2 - Landing page - Virtual training",
                "name": "Formation virtuelle Dropbox",
                "slug": "virtual-training",
                "introHero": {
                  "_id": "5Hy71DzB4jMPIMzXg2PFmC",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:26:18.543Z",
                  "internalTitle": "Hero - Virtual training",
                  "headerText": "Formation virtuelle Dropbox",
                  "summaryText": "Bénéficiez d’une formation Dropbox virtuelle et gratuite dispensée en direct par un instructeur. Développez vos compétences en participant à des sessions interactives dans une salle de classe virtuelle.\n",
                  "image": {
                    "title": "Learning Live Banner image",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6P5oPiTz2s9uchKdCXrtbj/1b95e452335e29c097137e710ade079c/Learning_Live_Banner_image.svg",
                    "size": 23584,
                    "width": 405,
                    "height": 310,
                    "filename": "Learning Live Banner image.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "2qm2M5L9rHtM93SagsoJ0P",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:26:32.733Z",
                    "internalTitle": "All training - Virtual training - Cards",
                    "title": "Sessions de formation virtuelle en direct",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Formation virtuelle Dropbox en direct | Découvrir Dropbox"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "Formation Dropbox virtuelle gratuite menée par un instructeur en direct. Bénéficiez d’une expérience pratique de Dropbox dans un environnement d’apprentissage restreint. Inscrivez-vous à un cours dès aujourd’hui."
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "session de formation virtuelle",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": ".",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "completedCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Les cours que vous avez terminés apparaîtront ici.",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "inProgressCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Rien à afficher pour l’instant. Commencez un ",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "75pzFP9abHFb2hlQycUS86",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/self-guided-learning",
                "_modifiedDate": "2025-03-18T22:08:04.576Z",
                "internalTitle": "L2 - Landing page - Self-guided learnings",
                "name": "Autoformation",
                "slug": "self-guided-learning",
                "introHero": {
                  "_id": "6pQlpoBglvzGW38DBWvU5W",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:24:30.062Z",
                  "internalTitle": "Hero - Self-guided learning",
                  "headerText": "Autoformation",
                  "summaryText": "Découvrez comment utiliser Dropbox en suivant ces cours qui s’adaptent à votre emploi du temps chargé.\n",
                  "image": {
                    "title": "Illustration d’autoformation",
                    "description": "Illustration de livre pour les cours d’autoformation de Dropbox",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6ODZb6bllZrucIYtiLqFkU/f844e955cabc76e34e249d9c18e43d62/self-guided-learning.svg",
                    "size": 42902,
                    "width": 405,
                    "height": 310,
                    "filename": "self-guided-learning.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "6MGcQfM5kOFGpBqiOmSkKy",
                    "_contentTypeId": "moduleFeaturedCourse",
                    "_modifiedDate": "2025-03-18T22:24:36.327Z",
                    "internalTitle": "Featured - Self-guided learning",
                    "title": "Cours à la une",
                    "course": null
                  },
                  {
                    "_id": "4JIWsH681XIFek4zmBB6Ix",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:25:12.491Z",
                    "internalTitle": "All courses - Self-guided learning - Cards",
                    "title": "Tous les cours",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Cours d’autoformation Dropbox | Découvrir Dropbox"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "Des formations Dropbox à votre propre rythme, disponibles gratuitement et à la demande. Découvrez comment utiliser votre compte Dropbox à votre propre rythme. Inscrivez-vous dès aujourd’hui."
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "cours d’autoformation",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": ".",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "l18nLookup": {
    "textHr": "h",
    "textHrs": "h",
    "textMin": "min",
    "textHour": "heure",
    "textLess": "Moins",
    "textMins": "min",
    "textMore": "Plus",
    "textRead": "Lues",
    "labelTags": "Mentions",
    "textClear": "Effacer",
    "textHours": "Heures",
    "textMinute": "minute",
    "ctaTextBack": "Retour",
    "ctaTextNext": "Suivant",
    "joinUrlText": "Rejoindre la session",
    "readingTime": "minutes de lecture",
    "textMinutes": "minutes",
    "textSeeMore": "Voir plus",
    "textViewAll": "Tout afficher",
    "searchHeader": "Rechercher",
    "textComplete": "Terminé",
    "textError404": "Erreur (404)",
    "textFilterBy": "Filtrer par",
    "ctaTextLogout": "Se déconnecter",
    "ctaTextSignIn": "Se connecter",
    "labelSubjects": "Objet",
    "textNextTopic": "Module suivant",
    "ctaTextProfile": "Profil",
    "ctaTextVirtual": "Afficher la session",
    "headerDuration": "Durée",
    "languageNameDe": "Deutsch",
    "languageNameEs": "Español",
    "languageNameFr": "Français",
    "languageNameIt": "Italiano",
    "languageNameJa": "日本語",
    "ctaTextRegister": "S’inscrire maintenant",
    "labelCategories": "Rubriques",
    "searchNoResults": "Aucun résultat pour {{search}}",
    "textStartCourse": "Commencer le cours",
    "textWebinarFull": "Ce webinaire est complet. Veuillez sélectionner un autre jour ou une autre heure.",
    "accountLabelLast": "Nom",
    "accountLabelName": "Nom",
    "ctaCreateAccount": "Créer un compte",
    "languageNameEnUs": "English",
    "languageNamePtBr": "Português",
    "searchBreadcrumb": "Résultats de la recherche",
    "searchFilterRole": "Fonction",
    "textErrorUnknown": "Un problème est survenu.",
    "textResumeCourse": "Reprendre le cours",
    "accountLabelEmail": "E‑mail",
    "ctaTextReschedule": "Changer la date",
    "ctaTextSelfGuided": "Afficher les modules",
    "ctaTextViewCourse": "Afficher le cours",
    "formLabelAttended": "A participé",
    "headerAccountInfo": "Infos sur le compte",
    "textErrorPageHome": "Accueil",
    "textPreviousTopic": "Module précédent",
    "ctaTextCancelClass": "Annuler ce cours",
    "formInputLabelDate": "Date",
    "headerPersonalInfo": "Informations personnelles",
    "labelSubcategories": "Sous-catégories",
    "searchResultStatus": "Results {{firstResult}}-{{lastResult}} of {{numResults}} for {{search}}",
    "textBreadcrumbRoot": "Accueil",
    "textCourseProgress": "Progression dans le cours",
    "formInputLabelEmail": "E‑mail",
    "globalTextCopyright": "Texte sur le copyright",
    "textDropdownDefault": "Les heures sont définies en fonction de votre fuseau horaire",
    "textErrorPageSignIn": "Se connecter",
    "textTableOfContents": "Modules",
    "canceledRegistration": "L’inscription a bien été annulée.",
    "formInputLabelCourse": "Cours",
    "formInputLabelStatus": "État",
    "searchFilterLanguage": "Langue",
    "searchResultsPerPage": "Résultats par page",
    "textSigninModalIntro": "Connectez-vous pour enregistrer votre progression ou continuez à faire défiler",
    "topicCountTextPlural": "Thèmes",
    "topicCountTextSingle": "sujet",
    "headerSuggestedTopics": "Modules proposés",
    "searchFilterOptionSgc": "Un cours à votre propre rythme",
    "searchResultTypeTopic": "Module",
    "searchResultTypeVideo": "Vidéo",
    "textAlreadyRegistered": "Vous êtes déjà inscrit à ce webinaire.",
    "textYouMustLogInFirst": "Vous devez d’abord vous connecter",
    "formInputLabelAttendee": "Participant",
    "formInputLabelLastName": "Nom",
    "formInputLabelOverview": "Présentation",
    "headerEventDescription": "Description de la session",
    "headerProfileCompleted": "Cours achevés",
    "searchFilterOptionLive": "Session en direct",
    "searchFilterOptionTeam": "Membres de l’équipe",
    "textCourseExpectations": "Nouveautés :",
    "textLearningObjectives": "Dans cette formation, vous découvrirez :",
    "formInputLabelFirstName": "Prénom",
    "headerProfileInProgress": "Cours commencés",
    "pageVideoCategoriesSync": "Synchronisation",
    "searchFilterContentType": "Type de contenu",
    "searchFilterOptionAdmin": "Administrateur",
    "searchFilterOptionTopic": "Module",
    "searchPageTitleMetadata": "Recherche | Découvrir Dropbox",
    "textErrorPageHelpCenter": "Centre d’assistance",
    "formInputLabelSelectDate": "Sélectionnez une date",
    "formInputLabelSelectTime": "Sélectionner une heure",
    "headerProfileLiveCourses": "Prochains cours",
    "pageVideoCategoriesShare": "Partager",
    "formInputLabelSessionLink": "Lien vers la session",
    "textErrorPageHelpfulLinks": "Voici quelques liens utiles :",
    "textErrorPagePageNotFound": "Nous ne trouvons pas la page que vous cherchez",
    "textRegistrationCompleted": "Votre inscription a été réalisée",
    "breadcrumbTextRegistration": "Inscription",
    "formInputLabelPickLanguage": "Sélectionnez votre langue",
    "pageVideoCategoriesWebinar": "Webinaire",
    "searchPageKeywordsMetadata": "rechercher",
    "searchResultTypeSelfGuided": "Cours",
    "ctaTextCompleteRegistration": "Terminez votre inscription",
    "headerEnterRegistrationInfo": "Saisissez vos informations d’inscription",
    "headerRegisteredLiveCourses": "Formations virtuelles enregistrées",
    "pageVideoCategoriesInstalls": "Installation",
    "pageVideoCategoriesOrganize": "Organiser",
    "pageVideoCategoriesProducts": "Produits",
    "pageVideoCategoriesSecurity": "Sécurité",
    "searchFilterOptionRoleAdmin": "Administrateur",
    "searchResultTypeLiveSession": "Session en direct",
    "headerRegistrationSuccessful": "Votre inscription a été réalisée correctement",
    "headerSearchInputPlaceholder": "Rechercher",
    "pageVideoSubcategoriesDelete": "Supprimer",
    "pageVideoSubcategoriesReplay": "Replay",
    "pageVideoSubcategoriesRewind": "Reculer",
    "pageVideoSubcategoriesSignIn": "Se connecter",
    "searchFilterOptionIndividual": "Particulier",
    "formInputLabelCourseCompleted": "Cours achevé",
    "pageVideoSubcategoriesCapture": "Capture",
    "pageVideoSubcategoriesRestore": "Restaurer",
    "searchPageDescriptionMetadata": "Vous pouvez rechercher des modules ou des informations pour en savoir plus sur nos cours. Trouvez la formation ou l’autoformation Dropbox adaptée à vos besoins.",
    "pageVideoCategoriesViewAndEdit": "Consulter et modifier",
    "pageVideoSubcategoriesTransfer": "Transfer",
    "searchFilterOptionRoleHelpDesk": "Service d’assistance",
    "textCalendarShortWeekdayFriday": "Ven",
    "textCalendarShortWeekdayMonday": "Lun",
    "textCalendarShortWeekdaySunday": "Dim",
    "pageVideoSubcategoriesMobileApp": "Application mobile",
    "textCalendarShortWeekdayTuesday": "Mar",
    "textEmailConfirmationWillBeSent": "Un e-mail de confirmation vous a été envoyé. Veuillez consulter votre boîte de réception.",
    "headerLiveCourseHighlightsHeader": "Vous découvrirez comment :",
    "pageVideoCategoriesAccountAccess": "Accès au compte",
    "pageVideoSubcategoriesTeamAccess": "Accès accordés à l’équipe",
    "searchFilterOptionRoleTeamMember": "Membres de l’équipe",
    "textCalendarShortWeekdaySaturday": "Sam",
    "textCalendarShortWeekdayThursday": "Jeu",
    "formInputLabelGeneralRegistration": "Inscription générale",
    "pageVideoSubcategoriesTeamFolders": "Dossiers d’équipe",
    "textCalendarShortWeekdayWednesday": "Mer",
    "formInputLabelGlobalSelectLanguage": "Sélectionner une langue",
    "formInputLabelSelectDateBreadcrumb": "Sélectionner une date",
    "pageVideoCategoriesAccountSettings": "Paramètres du compte",
    "pageVideoCategoriesCreateAndUpload": "Créer et importer",
    "pageVideoSubcategoriesTeamSecurity": "Sécurité de l’équipe",
    "searchFilterOptionRoleFundamentals": "Principes fondamentaux",
    "pageVideoCategoriesDeleteAndRestore": "Supprimer et restaurer",
    "pageCourseTopicTableOfContentsHeading": "Sections de cet article",
    "pageVideoSubcategoriesDropboxPolicies": "Politiques Dropbox",
    "pageVideoSubcategoriesCommentingOnFiles": "l’ajout de commentaires dans les fichiers",
    "pageVideoSubcategoriesSharingPermissions": "Autorisations de partage",
    "pageVideoSubcategoriesDropboxApplications": "Applications Dropbox",
    "pageVideoSubcategoriesSearchingYourAccount": "Recherche dans votre compte",
    "pageVideoSubcategoriesManagingAccountAccess": "Gestion de l’accès au compte",
    "pageVideoSubcategoriesSharingFilesAndFolders": "Partage de fichiers et de dossiers",
    "pageVideoSubcategoriesCreatingFilesAndFolders": "Création de fichiers et dossiers"
  },
  "copyrightText": "Dropbox 2021",
  "seo": {
    "title": {
      "name": "title",
      "value": "Dropbox learn"
    },
    "keywords": {
      "name": "keywords",
      "value": ""
    },
    "og:image": {
      "name": "og:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "og:title": {
      "name": "og:title",
      "value": "Dropbox learn"
    },
    "canonical": {
      "name": "canonical",
      "value": ""
    },
    "description": {
      "name": "description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:image": {
      "name": "twitter:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "twitter:title": {
      "name": "twitter:title",
      "value": "Dropbox learn"
    },
    "og:description": {
      "name": "og:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:description": {
      "name": "twitter:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    }
  },
  "seoOrganizationsSchema": {
    "url": "http://www.dropbox.com",
    "@type": "Organization",
    "@context": "http://schema.org",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": "TollFree"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "contactType": "customer service"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": [
          "HearingImpairedSupported",
          "TollFree"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "technical support",
        "contactOption": "TollFree",
        "availableLanguage": [
          "English",
          "French"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "bill payment",
        "contactOption": "TollFree"
      }
    ]
  }
}
  },
  "de": {
    isDefault: false,
    settings: {
  "_id": "4KRGLSrqTRY6s18UzHe1II",
  "_contentTypeId": "settingsGlobal",
  "_modifiedDate": "2024-10-08T22:02:32.138Z",
  "internalTitle": "CX Learning Hub Global Settings",
  "topNavigationLogo": {
    "title": "Dropbox Logo",
    "url": "//images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
    "size": 3707,
    "width": 324,
    "height": 64,
    "filename": "Dropbox.svg",
    "contentType": "image/svg+xml"
  },
  "mainNavigation": [
    {
      "internalTitle": "Supernav - Dropbox Help Center",
      "linkText": "Hilfecenter",
      "destinationType": "Manual text entry",
      "_id": "5pPOxJP1W9PaPBCnLHhe5x",
      "_contentTypeId": "elementLink",
      "href": "https://help.dropbox.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Community",
      "linkText": "Community",
      "destinationType": "Manual text entry",
      "_id": "7aVH719lug9lYEJzIntabM",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropboxforum.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox learn",
      "linkText": "Dropbox-Schulungen",
      "destinationType": "Content reference",
      "_id": "2EPydjcpXLep5NbdcRq3ca",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/home",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Contact us",
      "linkText": "Support kontaktieren",
      "destinationType": "Manual text entry",
      "_id": "5v3vwPdKulQu6xhA6csIT8",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropbox.com/support",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    }
  ],
  "secondaryNavigation": [
    {
      "internalTitle": "Secondary Nav - Self-guided learning",
      "linkText": "Selbstständiges Lernen",
      "destinationType": "Content reference",
      "_id": "oMqs4whMJuBjhZsbMv0KA",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/self-guided-learning",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Virtual training",
      "linkText": "Virtuelles Training",
      "destinationType": "Content reference",
      "_id": "31NB5ThxIWIxIju5dMFYC5",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/virtual-training",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Videos Landing Page",
      "linkText": "Videos",
      "destinationType": "Content reference",
      "_id": "5y8ArqrhCZ6DoCCGxaumhI",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/video-library",
      "target": null,
      "isModal": false,
      "download": false
    }
  ],
  "footerNavigation": [
    {
      "_id": "1M79uBMmyuD9Iw6fuOdVEJ",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:11:07.049Z",
      "internalTitle": "Dropbox",
      "links": [
        {
          "internalTitle": "Footer Link - Desktop app",
          "linkText": "Desktop-App",
          "destinationType": "Manual text entry",
          "_id": "3SKeLjF7MWEq2FqaRwnmiK",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/install?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Mobile apps",
          "linkText": "Mobile apps",
          "destinationType": "Manual text entry",
          "_id": "62ymoNSBbWiYinBkFO0cGj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/mobile?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Plans",
          "linkText": "Abos",
          "destinationType": "Manual text entry",
          "_id": "3Fdgsos4Pm77l2C4hKm2AC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plans?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Security",
          "linkText": "Sicherheit",
          "destinationType": "Manual text entry",
          "_id": "2YZlyLOZLw2lauy2OWiuB2",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/security?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Features",
          "linkText": "Features",
          "destinationType": "Manual text entry",
          "_id": "wUuQPtOLYz96QOSvtgLTh",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/features?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "6LXqBrZEH3p2eDbBpiPFlm",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:40.613Z",
      "internalTitle": "Produkte",
      "links": [
        {
          "internalTitle": "Footer Links - Plus",
          "linkText": "Plus",
          "destinationType": "Manual text entry",
          "_id": "4nThAv7YjSuKC91UBK1AoR",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plus?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links -  Professional",
          "linkText": "Professional",
          "destinationType": "Manual text entry",
          "_id": "5YtdEKxrCJgHqmmDvwgnQI",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/pro?trigger=_footer",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Business",
          "linkText": "Business",
          "destinationType": "Manual text entry",
          "_id": "5L4PFaY36Icmt95mUTgjOC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/business?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Enterprise",
          "linkText": "Enterprise",
          "destinationType": "Manual text entry",
          "_id": "4KJcgnikVfGiWIaFiuiRIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/enterprise?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "5xRp5jjLsl92vmGL0qJfVb",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:03:26.918Z",
      "internalTitle": "Firma:",
      "links": [
        {
          "internalTitle": "Footer Link - About us",
          "linkText": "Über uns",
          "destinationType": "Manual text entry",
          "_id": "6XnBD8OrJfQZ4u3ZSc6ysr",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/about",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Jobs",
          "linkText": "Jobs",
          "destinationType": "Manual text entry",
          "_id": "1nmf2X1QaLv7zS1PIyfqlE",
          "_contentTypeId": "elementLink",
          "href": "https://jobs.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Press",
          "linkText": "Presse",
          "destinationType": "Manual text entry",
          "_id": "5iMgmL64RXCXtz8iRkrIIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/news?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Investor relations",
          "linkText": "Investor Relations",
          "destinationType": "Manual text entry",
          "_id": "73WTKUyXSmqc5aP1OfisZ9",
          "_contentTypeId": "elementLink",
          "href": "https://investors.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Blog",
          "linkText": "Blog",
          "destinationType": "Manual text entry",
          "_id": "4mTcqsVCIHytbISiyJordW",
          "_contentTypeId": "elementLink",
          "href": "https://blog.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "1TnZWWGdwo9BEkEKgcIGpA",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:07:49.392Z",
      "internalTitle": "Support",
      "links": [
        {
          "internalTitle": "Supernav - Dropbox Help Center",
          "linkText": "Hilfecenter",
          "destinationType": "Manual text entry",
          "_id": "5pPOxJP1W9PaPBCnLHhe5x",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Supernav - Dropbox Contact us",
          "linkText": "Support kontaktieren",
          "destinationType": "Manual text entry",
          "_id": "5v3vwPdKulQu6xhA6csIT8",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/support",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Cookie Policy",
          "linkText": "Cookies-Richtlinie",
          "destinationType": "Manual text entry",
          "_id": "3akG8gNhd73ZlRaEYSCvUD",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/security/cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Manage Cookies",
          "linkText": "Cookies verwalten",
          "destinationType": "Manual text entry",
          "_id": "65mwIIQg9xKeyKiICuy9DJ",
          "_contentTypeId": "elementLink",
          "href": "#manage-cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Privacy & terms",
          "linkText": "Datenschutz & AGB",
          "destinationType": "Manual text entry",
          "_id": "30MJJnJ2DIcvhYL2wsg0FB",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/privacy?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Sitemap",
          "linkText": "Sitemap",
          "destinationType": "Manual text entry",
          "_id": "37byNy2Pux5TAcdSJm6g4f",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/sitemap?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "28o2jcc5DrafqCYZ9icYU7",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:42.647Z",
      "internalTitle": "Community",
      "links": [
        {
          "internalTitle": "Footer Links - Developers",
          "linkText": "Entwickler",
          "destinationType": "Manual text entry",
          "_id": "2aFb3GcSJS0YIEU3aKbLzj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/developers?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Referrals",
          "linkText": "Weiterempfehlungen",
          "destinationType": "Manual text entry",
          "_id": "4YsKqwQfAG4Ds0a0yeo9wp",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/referrals?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Forum",
          "linkText": "Forum",
          "destinationType": "Manual text entry",
          "_id": "4IGtm6twVWzfoE07kHlucc",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropboxforum.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    }
  ],
  "cookieBarBody": "Wir verwenden Cookies, damit Dropbox optimal für Sie funktioniert. Mit der Nutzung unserer Website stimmen Sie unserer Verwendung von Cookies zu.",
  "cookieBarCta": {
    "internalTitle": "Link Text - Cookie bar",
    "linkText": "Mehr erfahren",
    "destinationType": "Manual text entry",
    "_id": "1tY3PGTzdIHKRi4pDNbmSM",
    "_contentTypeId": "elementLink",
    "href": "https://help.dropbox.com/accounts-billing/security/cookies",
    "as": null,
    "target": "_blank",
    "isModal": false,
    "download": false
  },
  "cookieBarEnabled": true,
  "alertBarCta": null,
  "virtualTrainingsEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Hier gibt es noch nichts zu sehen – registrieren Sie sich für eine ",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "H59akjvvDv1tG26hXdjcK",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/virtual-training",
                "_modifiedDate": "2025-03-18T22:08:03.392Z",
                "internalTitle": "L2 - Landing page - Virtual training",
                "name": "Virtuelles Dropbox-Training",
                "slug": "virtual-training",
                "introHero": {
                  "_id": "5Hy71DzB4jMPIMzXg2PFmC",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:26:18.543Z",
                  "internalTitle": "Hero - Virtual training",
                  "headerText": "Virtuelles Dropbox-Training",
                  "summaryText": "Nehmen Sie an einem kostenlosen Dropbox-Online-Training teil. Unsere Schulungsleiter freuen sich schon auf Sie. Bei den interaktiven Schulungen in unserem virtuellen Seminarraum lernen Sie viel Neues.\n",
                  "image": {
                    "title": "Learning Live Banner image",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6P5oPiTz2s9uchKdCXrtbj/1b95e452335e29c097137e710ade079c/Learning_Live_Banner_image.svg",
                    "size": 23584,
                    "width": 405,
                    "height": 310,
                    "filename": "Learning Live Banner image.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "2qm2M5L9rHtM93SagsoJ0P",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:26:32.733Z",
                    "internalTitle": "All training - Virtual training - Cards",
                    "title": "Virtuelle Live-Schulungen",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Dropbox-Schulung mit virtuellem Live-Training | Dropbox-Schulungen"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "Kostenloses Dropbox-Online-Training, live mit Schulungsleiter. Sammeln Sie in übersichtlichen Kursen praktische Erfahrungen mit Dropbox. Registrieren Sie sich jetzt für einen Kurs."
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "virtuelle Schulung",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": ".",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "completedCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Kurse, die Sie besucht haben, werden hier angezeigt.",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "inProgressCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Hier gibt es noch nichts zu sehen – starten Sie einen ",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "75pzFP9abHFb2hlQycUS86",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/self-guided-learning",
                "_modifiedDate": "2025-03-18T22:08:04.576Z",
                "internalTitle": "L2 - Landing page - Self-guided learnings",
                "name": "Selbstständiges Lernen",
                "slug": "self-guided-learning",
                "introHero": {
                  "_id": "6pQlpoBglvzGW38DBWvU5W",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:24:30.062Z",
                  "internalTitle": "Hero - Self-guided learning",
                  "headerText": "Selbstständiges Lernen",
                  "summaryText": "Erfahren Sie, wie sich die Dropbox-Kurse nahtlos an Ihren ohnehin schon gut gefüllten Terminplan anpassen lassen.\n",
                  "image": {
                    "title": "Illustration zum selbstständigen Lernen",
                    "description": "Buchillustration für selbstgeführte Dropbox-Schulungen",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6ODZb6bllZrucIYtiLqFkU/f844e955cabc76e34e249d9c18e43d62/self-guided-learning.svg",
                    "size": 42902,
                    "width": 405,
                    "height": 310,
                    "filename": "self-guided-learning.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "6MGcQfM5kOFGpBqiOmSkKy",
                    "_contentTypeId": "moduleFeaturedCourse",
                    "_modifiedDate": "2025-03-18T22:24:36.327Z",
                    "internalTitle": "Featured - Self-guided learning",
                    "title": "Angebotener Kurs",
                    "course": null
                  },
                  {
                    "_id": "4JIWsH681XIFek4zmBB6Ix",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:25:12.491Z",
                    "internalTitle": "All courses - Self-guided learning - Cards",
                    "title": "Alle Kurse",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Selbstgeführte Dropbox-Trainingskurse | Dropbox-Schulungen"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "Dropbox-Trainingskurse für das Selbststudium, kostenlos und auf Abruf verfügbar. Erfahren Sie in Ihrem eigenen Tempo, wie Sie Ihr Dropbox-Konto verwenden. Registrieren Sie sich jetzt."
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "Kurs zum selbstständigen Lernen",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": ".",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "l18nLookup": {
    "textHr": "Std.",
    "textHrs": "Std.",
    "textMin": "min",
    "textHour": "Stunde",
    "textLess": "Weniger",
    "textMins": "Min.",
    "textMore": "Mehr",
    "textRead": "Gelesen",
    "labelTags": "Markierungen",
    "textClear": "Löschen",
    "textHours": "Verfügbarkeit",
    "textMinute": "Minute",
    "ctaTextBack": "Zurück",
    "ctaTextNext": "Weiter",
    "joinUrlText": "Der Sitzung beitreten",
    "readingTime": "Minute(n) Lesezeit",
    "textMinutes": "Minuten",
    "textSeeMore": "Mehr ansehen",
    "textViewAll": "Alle ansehen",
    "searchHeader": "Suchen",
    "textComplete": "Abgeschlossen",
    "textError404": "Fehler (404)",
    "textFilterBy": "Filtern nach",
    "ctaTextLogout": "Abmelden",
    "ctaTextSignIn": "Anmelden",
    "labelSubjects": "Betreff",
    "textNextTopic": "Nächstes Modul",
    "ctaTextProfile": "Profil",
    "ctaTextVirtual": "Sitzung anzeigen",
    "headerDuration": "Zeitraum",
    "languageNameDe": "Deutsch",
    "languageNameEs": "Español",
    "languageNameFr": "Français",
    "languageNameIt": "Italiano",
    "languageNameJa": "日本語",
    "ctaTextRegister": "Jetzt anmelden",
    "labelCategories": "Kategorien",
    "searchNoResults": "Keine Ergebnisse für {{search}}",
    "textStartCourse": "Jetzt Kurs starten",
    "textWebinarFull": "Dieses Webinar ist ausgebucht. Bitte versuchen Sie es mit einer anderen Uhrzeit oder einem anderen Tag.",
    "accountLabelLast": "Nachname",
    "accountLabelName": "Name",
    "ctaCreateAccount": "Konto erstellen",
    "languageNameEnUs": "English",
    "languageNamePtBr": "Português",
    "searchBreadcrumb": "Suchergebnisse",
    "searchFilterRole": "Nutzerrolle",
    "textErrorUnknown": "Ein Fehler ist aufgetreten",
    "textResumeCourse": "Kurs fortsetzen",
    "accountLabelEmail": "E-Mail",
    "ctaTextReschedule": "Verschieben",
    "ctaTextSelfGuided": "Module ansehen",
    "ctaTextViewCourse": "Zum Kurs",
    "formLabelAttended": "Besucht",
    "headerAccountInfo": "Kontoinformationen",
    "textErrorPageHome": "Start",
    "textPreviousTopic": "Vorheriges Modul",
    "ctaTextCancelClass": "Diesen Kurs abbrechen",
    "formInputLabelDate": "Datum",
    "headerPersonalInfo": "Persönliche Informationen",
    "labelSubcategories": "Unterkategorien",
    "searchResultStatus": "Results {{firstResult}}-{{lastResult}} of {{numResults}} for {{search}}",
    "textBreadcrumbRoot": "Start",
    "textCourseProgress": "Fortschritt",
    "formInputLabelEmail": "E-Mail",
    "globalTextCopyright": "Copyright-Text",
    "textDropdownDefault": "Die Zeiten richten sich nach Ihrer Zeitzone",
    "textErrorPageSignIn": "Anmelden",
    "textTableOfContents": "Module",
    "canceledRegistration": "Registrierung erfolgreich abgebrochen",
    "formInputLabelCourse": "Kurs",
    "formInputLabelStatus": "Status",
    "searchFilterLanguage": "Sprache",
    "searchResultsPerPage": "Ergebnisse pro Seite",
    "textSigninModalIntro": "Melden Sie sich an, um Ihren Fortschritt zu speichern oder weiter nach unten zu blättern",
    "topicCountTextPlural": "Themen",
    "topicCountTextSingle": "Thema",
    "headerSuggestedTopics": "Empfohlene Module",
    "searchFilterOptionSgc": "Kurse für das Selbststudium",
    "searchResultTypeTopic": "Modul",
    "searchResultTypeVideo": "Video",
    "textAlreadyRegistered": "Sie haben sich bereits für dieses Webinar registriert.",
    "textYouMustLogInFirst": "Sie müssen sich zuerst anmelden",
    "formInputLabelAttendee": "Teilnehmer",
    "formInputLabelLastName": "Nachname",
    "formInputLabelOverview": "Überblick",
    "headerEventDescription": "Beschreibung der Schulung",
    "headerProfileCompleted": "Abgeschlossene Kurse",
    "searchFilterOptionLive": "Live-Schulung",
    "searchFilterOptionTeam": "Teammitglied",
    "textCourseExpectations": "Was ändert sich?",
    "textLearningObjectives": "Welche Themen werden behandelt?",
    "formInputLabelFirstName": "Vorname",
    "headerProfileInProgress": "Laufende Kurse",
    "pageVideoCategoriesSync": "Synchronisierung",
    "searchFilterContentType": "Inhaltstyp",
    "searchFilterOptionAdmin": "Administrator",
    "searchFilterOptionTopic": "Modul",
    "searchPageTitleMetadata": "Suche | Dropbox-Schulungen",
    "textErrorPageHelpCenter": "Hilfecenter",
    "formInputLabelSelectDate": "Datum auswählen",
    "formInputLabelSelectTime": "Zeit auswählen",
    "headerProfileLiveCourses": "Künftige Kurse",
    "pageVideoCategoriesShare": "Freigeben",
    "formInputLabelSessionLink": "Link für die Sitzung",
    "textErrorPageHelpfulLinks": "Hier sind einige nützliche Links:",
    "textErrorPagePageNotFound": "Die gesuchte Seite wurde nicht gefunden.",
    "textRegistrationCompleted": "Sie sind registriert",
    "breadcrumbTextRegistration": "Registrierung",
    "formInputLabelPickLanguage": "Sprache auswählen",
    "pageVideoCategoriesWebinar": "Webinar",
    "searchPageKeywordsMetadata": "Suche",
    "searchResultTypeSelfGuided": "Kurs",
    "ctaTextCompleteRegistration": "Vervollständigen Sie Ihre Registrierung",
    "headerEnterRegistrationInfo": "Registrierungsdaten eingeben",
    "headerRegisteredLiveCourses": "Registrierte virtuelle Schulungen",
    "pageVideoCategoriesInstalls": "Installieren",
    "pageVideoCategoriesOrganize": "Organisieren",
    "pageVideoCategoriesProducts": "Produkte",
    "pageVideoCategoriesSecurity": "Sicherheit",
    "searchFilterOptionRoleAdmin": "Administrator",
    "searchResultTypeLiveSession": "Live-Schulung",
    "headerRegistrationSuccessful": "Sie sind jetzt registriert",
    "headerSearchInputPlaceholder": "Suchen",
    "pageVideoSubcategoriesDelete": "Löschen",
    "pageVideoSubcategoriesReplay": "Replay",
    "pageVideoSubcategoriesRewind": "Zurücksetzen",
    "pageVideoSubcategoriesSignIn": "Anmelden",
    "searchFilterOptionIndividual": "Einzelnutzer",
    "formInputLabelCourseCompleted": "Kurs abgeschlossen",
    "pageVideoSubcategoriesCapture": "Capture",
    "pageVideoSubcategoriesRestore": "Wiederherstellen",
    "searchPageDescriptionMetadata": "Sie können weitere Module und Informationen suchen, um mehr über unsere Kurse zu erfahren. Finden Sie das für Sie richtige Dropbox-Training oder einen passenden Kurs zum Selbststudium.",
    "pageVideoCategoriesViewAndEdit": "Lesen und bearbeiten",
    "pageVideoSubcategoriesTransfer": "Transfer",
    "searchFilterOptionRoleHelpDesk": "Helpdesk",
    "textCalendarShortWeekdayFriday": "Fr.",
    "textCalendarShortWeekdayMonday": "Mo.",
    "textCalendarShortWeekdaySunday": "So.",
    "pageVideoSubcategoriesMobileApp": "Mobile App",
    "textCalendarShortWeekdayTuesday": "Di.",
    "textEmailConfirmationWillBeSent": "Prüfen Sie Ihren Posteingang auf eine Bestätigungs-E-Mail.",
    "headerLiveCourseHighlightsHeader": "Diese Themen werden behandelt:",
    "pageVideoCategoriesAccountAccess": "Kontozugriff",
    "pageVideoSubcategoriesTeamAccess": "Teamzugriff",
    "searchFilterOptionRoleTeamMember": "Teammitglied",
    "textCalendarShortWeekdaySaturday": "Sa.",
    "textCalendarShortWeekdayThursday": "Do.",
    "formInputLabelGeneralRegistration": "Allgemeine Registrierung",
    "pageVideoSubcategoriesTeamFolders": "Team-Ordner",
    "textCalendarShortWeekdayWednesday": "Mi.",
    "formInputLabelGlobalSelectLanguage": "Sprache auswählen",
    "formInputLabelSelectDateBreadcrumb": "Datum auswählen",
    "pageVideoCategoriesAccountSettings": "Kontoeinstellungen",
    "pageVideoCategoriesCreateAndUpload": "Erstellen und hochladen",
    "pageVideoSubcategoriesTeamSecurity": "Team-Sicherheit",
    "searchFilterOptionRoleFundamentals": "Grundlagen",
    "pageVideoCategoriesDeleteAndRestore": "Löschen und wiederherstellen",
    "pageCourseTopicTableOfContentsHeading": "Inhalt",
    "pageVideoSubcategoriesDropboxPolicies": "Dropbox-Richtlinien",
    "pageVideoSubcategoriesCommentingOnFiles": "Das Kommentieren von Dateien",
    "pageVideoSubcategoriesSharingPermissions": "Freigabeberechtigungen",
    "pageVideoSubcategoriesDropboxApplications": "Dropbox-Apps",
    "pageVideoSubcategoriesSearchingYourAccount": "Ihr Konto durchsuchen",
    "pageVideoSubcategoriesManagingAccountAccess": "Kontozugriff verwalten",
    "pageVideoSubcategoriesSharingFilesAndFolders": "Dateien und Ordner freigeben",
    "pageVideoSubcategoriesCreatingFilesAndFolders": "Dateien und Ordner erstellen"
  },
  "copyrightText": "Dropbox 2021",
  "seo": {
    "title": {
      "name": "title",
      "value": "Dropbox learn"
    },
    "keywords": {
      "name": "keywords",
      "value": ""
    },
    "og:image": {
      "name": "og:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "og:title": {
      "name": "og:title",
      "value": "Dropbox learn"
    },
    "canonical": {
      "name": "canonical",
      "value": ""
    },
    "description": {
      "name": "description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:image": {
      "name": "twitter:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "twitter:title": {
      "name": "twitter:title",
      "value": "Dropbox learn"
    },
    "og:description": {
      "name": "og:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:description": {
      "name": "twitter:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    }
  },
  "seoOrganizationsSchema": {
    "url": "http://www.dropbox.com",
    "@type": "Organization",
    "@context": "http://schema.org",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": "TollFree"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "contactType": "customer service"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": [
          "HearingImpairedSupported",
          "TollFree"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "technical support",
        "contactOption": "TollFree",
        "availableLanguage": [
          "English",
          "French"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "bill payment",
        "contactOption": "TollFree"
      }
    ]
  }
}
  },
  "it": {
    isDefault: false,
    settings: {
  "_id": "4KRGLSrqTRY6s18UzHe1II",
  "_contentTypeId": "settingsGlobal",
  "_modifiedDate": "2024-10-08T22:02:32.138Z",
  "internalTitle": "CX Learning Hub Global Settings",
  "topNavigationLogo": {
    "title": "Dropbox Logo",
    "url": "//images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
    "size": 3707,
    "width": 324,
    "height": 64,
    "filename": "Dropbox.svg",
    "contentType": "image/svg+xml"
  },
  "mainNavigation": [
    {
      "internalTitle": "Supernav - Dropbox Help Center",
      "linkText": "Centro assistenza",
      "destinationType": "Manual text entry",
      "_id": "5pPOxJP1W9PaPBCnLHhe5x",
      "_contentTypeId": "elementLink",
      "href": "https://help.dropbox.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Community",
      "linkText": "Community",
      "destinationType": "Manual text entry",
      "_id": "7aVH719lug9lYEJzIntabM",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropboxforum.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox learn",
      "linkText": "Formazione su Dropbox",
      "destinationType": "Content reference",
      "_id": "2EPydjcpXLep5NbdcRq3ca",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/home",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Contact us",
      "linkText": "Contatta il supporto",
      "destinationType": "Manual text entry",
      "_id": "5v3vwPdKulQu6xhA6csIT8",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropbox.com/support",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    }
  ],
  "secondaryNavigation": [
    {
      "internalTitle": "Secondary Nav - Self-guided learning",
      "linkText": "Formazione autoguidata",
      "destinationType": "Content reference",
      "_id": "oMqs4whMJuBjhZsbMv0KA",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/self-guided-learning",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Virtual training",
      "linkText": "Formazione virtuale",
      "destinationType": "Content reference",
      "_id": "31NB5ThxIWIxIju5dMFYC5",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/virtual-training",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Videos Landing Page",
      "linkText": "Video",
      "destinationType": "Content reference",
      "_id": "5y8ArqrhCZ6DoCCGxaumhI",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/video-library",
      "target": null,
      "isModal": false,
      "download": false
    }
  ],
  "footerNavigation": [
    {
      "_id": "1M79uBMmyuD9Iw6fuOdVEJ",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:11:07.049Z",
      "internalTitle": "Dropbox",
      "links": [
        {
          "internalTitle": "Footer Link - Desktop app",
          "linkText": "Applicazione desktop",
          "destinationType": "Manual text entry",
          "_id": "3SKeLjF7MWEq2FqaRwnmiK",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/install?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Mobile apps",
          "linkText": "App per dispositivi mobili",
          "destinationType": "Manual text entry",
          "_id": "62ymoNSBbWiYinBkFO0cGj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/mobile?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Plans",
          "linkText": "Piani",
          "destinationType": "Manual text entry",
          "_id": "3Fdgsos4Pm77l2C4hKm2AC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plans?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Security",
          "linkText": "Sicurezza",
          "destinationType": "Manual text entry",
          "_id": "2YZlyLOZLw2lauy2OWiuB2",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/security?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Features",
          "linkText": "Funzioni",
          "destinationType": "Manual text entry",
          "_id": "wUuQPtOLYz96QOSvtgLTh",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/features?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "6LXqBrZEH3p2eDbBpiPFlm",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:40.613Z",
      "internalTitle": "Prodotti",
      "links": [
        {
          "internalTitle": "Footer Links - Plus",
          "linkText": "Plus",
          "destinationType": "Manual text entry",
          "_id": "4nThAv7YjSuKC91UBK1AoR",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plus?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links -  Professional",
          "linkText": "Professional",
          "destinationType": "Manual text entry",
          "_id": "5YtdEKxrCJgHqmmDvwgnQI",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/pro?trigger=_footer",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Business",
          "linkText": "Settore",
          "destinationType": "Manual text entry",
          "_id": "5L4PFaY36Icmt95mUTgjOC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/business?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Enterprise",
          "linkText": "Enterprise",
          "destinationType": "Manual text entry",
          "_id": "4KJcgnikVfGiWIaFiuiRIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/enterprise?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "5xRp5jjLsl92vmGL0qJfVb",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:03:26.918Z",
      "internalTitle": "Azienda",
      "links": [
        {
          "internalTitle": "Footer Link - About us",
          "linkText": "Chi siamo",
          "destinationType": "Manual text entry",
          "_id": "6XnBD8OrJfQZ4u3ZSc6ysr",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/about",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Jobs",
          "linkText": "Jobs",
          "destinationType": "Manual text entry",
          "_id": "1nmf2X1QaLv7zS1PIyfqlE",
          "_contentTypeId": "elementLink",
          "href": "https://jobs.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Press",
          "linkText": "Press",
          "destinationType": "Manual text entry",
          "_id": "5iMgmL64RXCXtz8iRkrIIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/news?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Investor relations",
          "linkText": "Relazioni con gli investitori",
          "destinationType": "Manual text entry",
          "_id": "73WTKUyXSmqc5aP1OfisZ9",
          "_contentTypeId": "elementLink",
          "href": "https://investors.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Blog",
          "linkText": "Blog",
          "destinationType": "Manual text entry",
          "_id": "4mTcqsVCIHytbISiyJordW",
          "_contentTypeId": "elementLink",
          "href": "https://blog.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "1TnZWWGdwo9BEkEKgcIGpA",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:07:49.392Z",
      "internalTitle": "Supporto",
      "links": [
        {
          "internalTitle": "Supernav - Dropbox Help Center",
          "linkText": "Centro assistenza",
          "destinationType": "Manual text entry",
          "_id": "5pPOxJP1W9PaPBCnLHhe5x",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Supernav - Dropbox Contact us",
          "linkText": "Contatta il supporto",
          "destinationType": "Manual text entry",
          "_id": "5v3vwPdKulQu6xhA6csIT8",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/support",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Cookie Policy",
          "linkText": "Cookie",
          "destinationType": "Manual text entry",
          "_id": "3akG8gNhd73ZlRaEYSCvUD",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/security/cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Manage Cookies",
          "linkText": "Gestisci cookie",
          "destinationType": "Manual text entry",
          "_id": "65mwIIQg9xKeyKiICuy9DJ",
          "_contentTypeId": "elementLink",
          "href": "#manage-cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Privacy & terms",
          "linkText": "Privacy e Termini",
          "destinationType": "Manual text entry",
          "_id": "30MJJnJ2DIcvhYL2wsg0FB",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/privacy?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Sitemap",
          "linkText": "Mappa del sito",
          "destinationType": "Manual text entry",
          "_id": "37byNy2Pux5TAcdSJm6g4f",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/sitemap?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "28o2jcc5DrafqCYZ9icYU7",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:42.647Z",
      "internalTitle": "Community",
      "links": [
        {
          "internalTitle": "Footer Links - Developers",
          "linkText": "Developers",
          "destinationType": "Manual text entry",
          "_id": "2aFb3GcSJS0YIEU3aKbLzj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/developers?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Referrals",
          "linkText": "Referral",
          "destinationType": "Manual text entry",
          "_id": "4YsKqwQfAG4Ds0a0yeo9wp",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/referrals?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Forum",
          "linkText": "Forum",
          "destinationType": "Manual text entry",
          "_id": "4IGtm6twVWzfoE07kHlucc",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropboxforum.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    }
  ],
  "cookieBarBody": "Usiamo i cookie per rendere possibile il corretto funzionamento di Dropbox per te. Utilizzando il nostro sito, accetti le nostre modalità di utilizzo dei cookie.",
  "cookieBarCta": {
    "internalTitle": "Link Text - Cookie bar",
    "linkText": "Ulteriori informazioni",
    "destinationType": "Manual text entry",
    "_id": "1tY3PGTzdIHKRi4pDNbmSM",
    "_contentTypeId": "elementLink",
    "href": "https://help.dropbox.com/accounts-billing/security/cookies",
    "as": null,
    "target": "_blank",
    "isModal": false,
    "download": false
  },
  "cookieBarEnabled": true,
  "alertBarCta": null,
  "virtualTrainingsEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Ancora nessun elemento da visualizzare. Iscriviti a una ",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "H59akjvvDv1tG26hXdjcK",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/virtual-training",
                "_modifiedDate": "2025-03-18T22:08:03.392Z",
                "internalTitle": "L2 - Landing page - Virtual training",
                "name": "Formazione virtuale su Dropbox",
                "slug": "virtual-training",
                "introHero": {
                  "_id": "5Hy71DzB4jMPIMzXg2PFmC",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:26:18.543Z",
                  "internalTitle": "Hero - Virtual training",
                  "headerText": "Formazione virtuale su Dropbox",
                  "summaryText": "Formazione online gratuita su Dropbox con istruttore dal vivo. Acquisisci nuove competenze attraverso sessioni interattive in un'aula virtuale.\n",
                  "image": {
                    "title": "Learning Live Banner image",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6P5oPiTz2s9uchKdCXrtbj/1b95e452335e29c097137e710ade079c/Learning_Live_Banner_image.svg",
                    "size": 23584,
                    "width": 405,
                    "height": 310,
                    "filename": "Learning Live Banner image.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "2qm2M5L9rHtM93SagsoJ0P",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:26:32.733Z",
                    "internalTitle": "All training - Virtual training - Cards",
                    "title": "Sessioni di formazione virtuali dal vivo",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Formazione virtuale live su Dropbox | Formazione su Dropbox"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "Formazione online gratuita su Dropbox con un istruttore virtuale live. Fai un'esperienza pratica in un contesto di formazione ristretto. Registrati oggi stesso."
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "sessione di formazione virtuale",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": ".",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "completedCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "I corsi che hai completato compariranno qui.",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "inProgressCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Ancora nessun elemento da visualizzare. Inizia un ",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "75pzFP9abHFb2hlQycUS86",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/self-guided-learning",
                "_modifiedDate": "2025-03-18T22:08:04.576Z",
                "internalTitle": "L2 - Landing page - Self-guided learnings",
                "name": "Formazione autoguidata",
                "slug": "self-guided-learning",
                "introHero": {
                  "_id": "6pQlpoBglvzGW38DBWvU5W",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:24:30.062Z",
                  "internalTitle": "Hero - Self-guided learning",
                  "headerText": "Formazione autoguidata",
                  "summaryText": "Scopri come utilizzare Dropbox con i corsi pensati per adattarsi ai tuoi impegni.\n",
                  "image": {
                    "title": "Illustrazione per la formazione autoguidata",
                    "description": "Illustrazione di un libro per i corsi di formazione autoguidata di Dropbox",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6ODZb6bllZrucIYtiLqFkU/f844e955cabc76e34e249d9c18e43d62/self-guided-learning.svg",
                    "size": 42902,
                    "width": 405,
                    "height": 310,
                    "filename": "self-guided-learning.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "6MGcQfM5kOFGpBqiOmSkKy",
                    "_contentTypeId": "moduleFeaturedCourse",
                    "_modifiedDate": "2025-03-18T22:24:36.327Z",
                    "internalTitle": "Featured - Self-guided learning",
                    "title": "Corso in primo piano",
                    "course": null
                  },
                  {
                    "_id": "4JIWsH681XIFek4zmBB6Ix",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:25:12.491Z",
                    "internalTitle": "All courses - Self-guided learning - Cards",
                    "title": "Tutti i corsi",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Corsi di formazione autoguidata su Dropbox | Formazione su Dropbox"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "Corsi di formazione Dropbox autonomi, disponibili gratuitamente e su richiesta. Scopri come utilizzare il tuo account Dropbox nel modo che preferisci. Iscriviti oggi stesso."
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "corso di formazione autoguidato",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": ".",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "l18nLookup": {
    "textHr": "h",
    "textHrs": "ore",
    "textMin": "min",
    "textHour": "ora",
    "textLess": "Meno",
    "textMins": "min",
    "textMore": "Altro",
    "textRead": "Lettura",
    "labelTags": "Tag",
    "textClear": "Cancella",
    "textHours": "Orari",
    "textMinute": "minuto",
    "ctaTextBack": "Indietro",
    "ctaTextNext": "Successivo",
    "joinUrlText": "Partecipa alla sessione",
    "readingTime": "minuti di lettura",
    "textMinutes": "minuti",
    "textSeeMore": "Visualizza altro",
    "textViewAll": "Visualizza tutto",
    "searchHeader": "Cerca",
    "textComplete": "Completato",
    "textError404": "Errore (404)",
    "textFilterBy": "Filtra per",
    "ctaTextLogout": "Esci",
    "ctaTextSignIn": "Accedi",
    "labelSubjects": "Argomenti",
    "textNextTopic": "Modulo successivo",
    "ctaTextProfile": "Profilo",
    "ctaTextVirtual": "Visualizza sessione",
    "headerDuration": "Durata",
    "languageNameDe": "Deutsch",
    "languageNameEs": "Español",
    "languageNameFr": "Français",
    "languageNameIt": "Italiano",
    "languageNameJa": "日本語",
    "ctaTextRegister": "Registrati adesso",
    "labelCategories": "Categorie",
    "searchNoResults": "Nessun risultato per {{search}}",
    "textStartCourse": "Inizia il corso",
    "textWebinarFull": "Il webinar è al completo. Scegli un altro orario o giorno.",
    "accountLabelLast": "Cognome",
    "accountLabelName": "Nome",
    "ctaCreateAccount": "Crea account",
    "languageNameEnUs": "English",
    "languageNamePtBr": "Português",
    "searchBreadcrumb": "Risultati di ricerca",
    "searchFilterRole": "Ruolo",
    "textErrorUnknown": "Si è verificato un errore",
    "textResumeCourse": "Riprendi corso",
    "accountLabelEmail": "Email",
    "ctaTextReschedule": "Ripianifica",
    "ctaTextSelfGuided": "Visualizza moduli",
    "ctaTextViewCourse": "Visualizza corso",
    "formLabelAttended": "Seguito",
    "headerAccountInfo": "Info account",
    "textErrorPageHome": "Home page",
    "textPreviousTopic": "Modulo precedente",
    "ctaTextCancelClass": "Annulla questa lezione",
    "formInputLabelDate": "Data",
    "headerPersonalInfo": "Informazioni personali",
    "labelSubcategories": "Sottocategorie",
    "searchResultStatus": "Results {{firstResult}}-{{lastResult}} of {{numResults}} for {{search}}",
    "textBreadcrumbRoot": "Home page",
    "textCourseProgress": "Progresso corso",
    "formInputLabelEmail": "Email",
    "globalTextCopyright": "Testo del copyright",
    "textDropdownDefault": "Gli orari mostrati si riferiscono al tuo fuso orario",
    "textErrorPageSignIn": "Accedi",
    "textTableOfContents": "Moduli",
    "canceledRegistration": "Registrazione annullata correttamente",
    "formInputLabelCourse": "Corso",
    "formInputLabelStatus": "Stato",
    "searchFilterLanguage": "Lingua",
    "searchResultsPerPage": "Risultati per pagina",
    "textSigninModalIntro": "Accedi per salvare i tuoi progressi o continuare a scorrere",
    "topicCountTextPlural": "Argomenti",
    "topicCountTextSingle": "Argomento",
    "headerSuggestedTopics": "Moduli suggeriti",
    "searchFilterOptionSgc": "Corso autodidattico",
    "searchResultTypeTopic": "Modulo",
    "searchResultTypeVideo": "Video",
    "textAlreadyRegistered": "Hai già effettuato l’iscrizione a questo webinar.",
    "textYouMustLogInFirst": "Prima devi effettuare l'accesso",
    "formInputLabelAttendee": "Partecipante",
    "formInputLabelLastName": "Cognome",
    "formInputLabelOverview": "Panoramica",
    "headerEventDescription": "Descrizione della sessione",
    "headerProfileCompleted": "Corsi completati",
    "searchFilterOptionLive": "Sessione live",
    "searchFilterOptionTeam": "Membro del team",
    "textCourseExpectations": "Che cosa aspettarsi:",
    "textLearningObjectives": "Contenuto del corso",
    "formInputLabelFirstName": "Nome",
    "headerProfileInProgress": "Corsi attuali",
    "pageVideoCategoriesSync": "Sincronizzazione",
    "searchFilterContentType": "Tipo di contenuti",
    "searchFilterOptionAdmin": "Amministratore",
    "searchFilterOptionTopic": "Modulo",
    "searchPageTitleMetadata": "Ricerca | Formazione su Dropbox",
    "textErrorPageHelpCenter": "Centro assistenza",
    "formInputLabelSelectDate": "Seleziona una data",
    "formInputLabelSelectTime": "Seleziona un orario",
    "headerProfileLiveCourses": "Corsi in programma",
    "pageVideoCategoriesShare": "Condividi",
    "formInputLabelSessionLink": "Link alla sessione",
    "textErrorPageHelpfulLinks": "Ecco alcuni utili link:",
    "textErrorPagePageNotFound": "Non riusciamo a trovare la pagina che stai cercando",
    "textRegistrationCompleted": "Hai effettuato la registrazione",
    "breadcrumbTextRegistration": "Registrazione",
    "formInputLabelPickLanguage": "Seleziona la lingua",
    "pageVideoCategoriesWebinar": "Webinar",
    "searchPageKeywordsMetadata": "ricerca",
    "searchResultTypeSelfGuided": "Corso",
    "ctaTextCompleteRegistration": "Completa la registrazione",
    "headerEnterRegistrationInfo": "Inserisci le informazioni di registrazione",
    "headerRegisteredLiveCourses": "Corsi di formazione virtuali registrati",
    "pageVideoCategoriesInstalls": "Installazione in corso",
    "pageVideoCategoriesOrganize": "Organizza",
    "pageVideoCategoriesProducts": "Prodotti",
    "pageVideoCategoriesSecurity": "Sicurezza",
    "searchFilterOptionRoleAdmin": "Amministratore",
    "searchResultTypeLiveSession": "Sessione live",
    "headerRegistrationSuccessful": "Hai effettuato la registrazione correttamente",
    "headerSearchInputPlaceholder": "Cerca",
    "pageVideoSubcategoriesDelete": "Elimina",
    "pageVideoSubcategoriesReplay": "Replay",
    "pageVideoSubcategoriesRewind": "Riavvolgimento",
    "pageVideoSubcategoriesSignIn": "Accedi",
    "searchFilterOptionIndividual": "Utente singolo",
    "formInputLabelCourseCompleted": "Corso completato",
    "pageVideoSubcategoriesCapture": "Capture",
    "pageVideoSubcategoriesRestore": "Ripristina",
    "searchPageDescriptionMetadata": "Puoi cercare moduli o informazioni per scoprire di più sulle nostre risorse di formazione. Trova il percorso o il corso autoguidato Dropbox più adatto alle tue esigenze.",
    "pageVideoCategoriesViewAndEdit": "Visualizza e modifica",
    "pageVideoSubcategoriesTransfer": "Transfer",
    "searchFilterOptionRoleHelpDesk": "Help desk",
    "textCalendarShortWeekdayFriday": "Ven",
    "textCalendarShortWeekdayMonday": "Lun",
    "textCalendarShortWeekdaySunday": "Dom",
    "pageVideoSubcategoriesMobileApp": "Applicazione per dispositivi mobili",
    "textCalendarShortWeekdayTuesday": "Mar",
    "textEmailConfirmationWillBeSent": "Controlla la posta in arrivo per vedere se hai ricevuto un'email di conferma.",
    "headerLiveCourseHighlightsHeader": "Contenuto del corso:",
    "pageVideoCategoriesAccountAccess": "Accesso all'account",
    "pageVideoSubcategoriesTeamAccess": "Accesso del team",
    "searchFilterOptionRoleTeamMember": "Membro del team",
    "textCalendarShortWeekdaySaturday": "Sab",
    "textCalendarShortWeekdayThursday": "Gio",
    "formInputLabelGeneralRegistration": "Registrazione generale",
    "pageVideoSubcategoriesTeamFolders": "Cartelle del team",
    "textCalendarShortWeekdayWednesday": "Mer",
    "formInputLabelGlobalSelectLanguage": "Seleziona una lingua",
    "formInputLabelSelectDateBreadcrumb": "Seleziona data",
    "pageVideoCategoriesAccountSettings": "Impostazioni account",
    "pageVideoCategoriesCreateAndUpload": "Crea e carica",
    "pageVideoSubcategoriesTeamSecurity": "Sicurezza del team",
    "searchFilterOptionRoleFundamentals": "Fondamenti",
    "pageVideoCategoriesDeleteAndRestore": "Eliminazione e ripristino",
    "pageCourseTopicTableOfContentsHeading": "Sezioni in questo articolo",
    "pageVideoSubcategoriesDropboxPolicies": "Norme di Dropbox",
    "pageVideoSubcategoriesCommentingOnFiles": "Aggiunta di commenti ai file",
    "pageVideoSubcategoriesSharingPermissions": "Autorizzazioni di condivisione",
    "pageVideoSubcategoriesDropboxApplications": "Applicazioni Dropbox",
    "pageVideoSubcategoriesSearchingYourAccount": "Cercare nel proprio account",
    "pageVideoSubcategoriesManagingAccountAccess": "Gestire l’accesso all’account",
    "pageVideoSubcategoriesSharingFilesAndFolders": "Condividi file e cartelle",
    "pageVideoSubcategoriesCreatingFilesAndFolders": "Creare file e cartelle"
  },
  "copyrightText": "Dropbox 2021",
  "seo": {
    "title": {
      "name": "title",
      "value": "Dropbox learn"
    },
    "keywords": {
      "name": "keywords",
      "value": ""
    },
    "og:image": {
      "name": "og:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "og:title": {
      "name": "og:title",
      "value": "Dropbox learn"
    },
    "canonical": {
      "name": "canonical",
      "value": ""
    },
    "description": {
      "name": "description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:image": {
      "name": "twitter:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "twitter:title": {
      "name": "twitter:title",
      "value": "Dropbox learn"
    },
    "og:description": {
      "name": "og:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:description": {
      "name": "twitter:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    }
  },
  "seoOrganizationsSchema": {
    "url": "http://www.dropbox.com",
    "@type": "Organization",
    "@context": "http://schema.org",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": "TollFree"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "contactType": "customer service"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": [
          "HearingImpairedSupported",
          "TollFree"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "technical support",
        "contactOption": "TollFree",
        "availableLanguage": [
          "English",
          "French"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "bill payment",
        "contactOption": "TollFree"
      }
    ]
  }
}
  },
  "ja": {
    isDefault: false,
    settings: {
  "_id": "4KRGLSrqTRY6s18UzHe1II",
  "_contentTypeId": "settingsGlobal",
  "_modifiedDate": "2024-10-08T22:02:32.138Z",
  "internalTitle": "CX Learning Hub Global Settings",
  "topNavigationLogo": {
    "title": "Dropbox Logo",
    "url": "//images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
    "size": 3707,
    "width": 324,
    "height": 64,
    "filename": "Dropbox.svg",
    "contentType": "image/svg+xml"
  },
  "mainNavigation": [
    {
      "internalTitle": "Supernav - Dropbox Help Center",
      "linkText": "ヘルプセンター",
      "destinationType": "Manual text entry",
      "_id": "5pPOxJP1W9PaPBCnLHhe5x",
      "_contentTypeId": "elementLink",
      "href": "https://help.dropbox.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Community",
      "linkText": "コミュニティ",
      "destinationType": "Manual text entry",
      "_id": "7aVH719lug9lYEJzIntabM",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropboxforum.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox learn",
      "linkText": "Dropbox について学ぶ",
      "destinationType": "Content reference",
      "_id": "2EPydjcpXLep5NbdcRq3ca",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/home",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Contact us",
      "linkText": "サポートへのお問い合わせ",
      "destinationType": "Manual text entry",
      "_id": "5v3vwPdKulQu6xhA6csIT8",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropbox.com/support",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    }
  ],
  "secondaryNavigation": [
    {
      "internalTitle": "Secondary Nav - Self-guided learning",
      "linkText": "自分のペースで進められるトレーニング",
      "destinationType": "Content reference",
      "_id": "oMqs4whMJuBjhZsbMv0KA",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/self-guided-learning",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Virtual training",
      "linkText": "バーチャル トレーニング",
      "destinationType": "Content reference",
      "_id": "31NB5ThxIWIxIju5dMFYC5",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/virtual-training",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Videos Landing Page",
      "linkText": "ビデオ",
      "destinationType": "Content reference",
      "_id": "5y8ArqrhCZ6DoCCGxaumhI",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/video-library",
      "target": null,
      "isModal": false,
      "download": false
    }
  ],
  "footerNavigation": [
    {
      "_id": "1M79uBMmyuD9Iw6fuOdVEJ",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:11:07.049Z",
      "internalTitle": "Dropbox",
      "links": [
        {
          "internalTitle": "Footer Link - Desktop app",
          "linkText": "デスクトップ アプリ",
          "destinationType": "Manual text entry",
          "_id": "3SKeLjF7MWEq2FqaRwnmiK",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/install?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Mobile apps",
          "linkText": "モバイル アプリ",
          "destinationType": "Manual text entry",
          "_id": "62ymoNSBbWiYinBkFO0cGj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/mobile?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Plans",
          "linkText": "プラン",
          "destinationType": "Manual text entry",
          "_id": "3Fdgsos4Pm77l2C4hKm2AC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plans?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Security",
          "linkText": "セキュリティ",
          "destinationType": "Manual text entry",
          "_id": "2YZlyLOZLw2lauy2OWiuB2",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/security?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Features",
          "linkText": "機能",
          "destinationType": "Manual text entry",
          "_id": "wUuQPtOLYz96QOSvtgLTh",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/features?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "6LXqBrZEH3p2eDbBpiPFlm",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:40.613Z",
      "internalTitle": "製品",
      "links": [
        {
          "internalTitle": "Footer Links - Plus",
          "linkText": "Plus",
          "destinationType": "Manual text entry",
          "_id": "4nThAv7YjSuKC91UBK1AoR",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plus?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links -  Professional",
          "linkText": "Professional",
          "destinationType": "Manual text entry",
          "_id": "5YtdEKxrCJgHqmmDvwgnQI",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/pro?trigger=_footer",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Business",
          "linkText": "Business",
          "destinationType": "Manual text entry",
          "_id": "5L4PFaY36Icmt95mUTgjOC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/business?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Enterprise",
          "linkText": "Enterprise",
          "destinationType": "Manual text entry",
          "_id": "4KJcgnikVfGiWIaFiuiRIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/enterprise?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "5xRp5jjLsl92vmGL0qJfVb",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:03:26.918Z",
      "internalTitle": "企業",
      "links": [
        {
          "internalTitle": "Footer Link - About us",
          "linkText": "Dropbox について",
          "destinationType": "Manual text entry",
          "_id": "6XnBD8OrJfQZ4u3ZSc6ysr",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/about",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Jobs",
          "linkText": "採用情報",
          "destinationType": "Manual text entry",
          "_id": "1nmf2X1QaLv7zS1PIyfqlE",
          "_contentTypeId": "elementLink",
          "href": "https://jobs.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Press",
          "linkText": "メディア",
          "destinationType": "Manual text entry",
          "_id": "5iMgmL64RXCXtz8iRkrIIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/news?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Investor relations",
          "linkText": "IR 情報",
          "destinationType": "Manual text entry",
          "_id": "73WTKUyXSmqc5aP1OfisZ9",
          "_contentTypeId": "elementLink",
          "href": "https://investors.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Blog",
          "linkText": "ブログ",
          "destinationType": "Manual text entry",
          "_id": "4mTcqsVCIHytbISiyJordW",
          "_contentTypeId": "elementLink",
          "href": "https://blog.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "1TnZWWGdwo9BEkEKgcIGpA",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:07:49.392Z",
      "internalTitle": "サポート",
      "links": [
        {
          "internalTitle": "Supernav - Dropbox Help Center",
          "linkText": "ヘルプセンター",
          "destinationType": "Manual text entry",
          "_id": "5pPOxJP1W9PaPBCnLHhe5x",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Supernav - Dropbox Contact us",
          "linkText": "サポートへのお問い合わせ",
          "destinationType": "Manual text entry",
          "_id": "5v3vwPdKulQu6xhA6csIT8",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/support",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Cookie Policy",
          "linkText": "Cookie ポリシー",
          "destinationType": "Manual text entry",
          "_id": "3akG8gNhd73ZlRaEYSCvUD",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/security/cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Manage Cookies",
          "linkText": "Cookie を管理する",
          "destinationType": "Manual text entry",
          "_id": "65mwIIQg9xKeyKiICuy9DJ",
          "_contentTypeId": "elementLink",
          "href": "#manage-cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Privacy & terms",
          "linkText": "プライバシーと利用規約",
          "destinationType": "Manual text entry",
          "_id": "30MJJnJ2DIcvhYL2wsg0FB",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/privacy?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Sitemap",
          "linkText": "サイトマップ",
          "destinationType": "Manual text entry",
          "_id": "37byNy2Pux5TAcdSJm6g4f",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/sitemap?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "28o2jcc5DrafqCYZ9icYU7",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:42.647Z",
      "internalTitle": "コミュニティ",
      "links": [
        {
          "internalTitle": "Footer Links - Developers",
          "linkText": "開発者数",
          "destinationType": "Manual text entry",
          "_id": "2aFb3GcSJS0YIEU3aKbLzj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/developers?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Referrals",
          "linkText": "紹介",
          "destinationType": "Manual text entry",
          "_id": "4YsKqwQfAG4Ds0a0yeo9wp",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/referrals?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Forum",
          "linkText": "フォーラム",
          "destinationType": "Manual text entry",
          "_id": "4IGtm6twVWzfoE07kHlucc",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropboxforum.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    }
  ],
  "cookieBarBody": "Dropbox ではパフォーマンス向上のために Cookie を使用しています。Dropbox ウェブサイトを使用することにより、Cookie の使用に同意するものとみなします。",
  "cookieBarCta": {
    "internalTitle": "Link Text - Cookie bar",
    "linkText": "詳しくはこちら",
    "destinationType": "Manual text entry",
    "_id": "1tY3PGTzdIHKRi4pDNbmSM",
    "_contentTypeId": "elementLink",
    "href": "https://help.dropbox.com/accounts-billing/security/cookies",
    "as": null,
    "target": "_blank",
    "isModal": false,
    "download": false
  },
  "cookieBarEnabled": true,
  "alertBarCta": null,
  "virtualTrainingsEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "まだ何もありません。",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "H59akjvvDv1tG26hXdjcK",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/virtual-training",
                "_modifiedDate": "2025-03-18T22:08:03.392Z",
                "internalTitle": "L2 - Landing page - Virtual training",
                "name": "Dropbox バーチャル トレーニング",
                "slug": "virtual-training",
                "introHero": {
                  "_id": "5Hy71DzB4jMPIMzXg2PFmC",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:26:18.543Z",
                  "internalTitle": "Hero - Virtual training",
                  "headerText": "Dropbox バーチャル トレーニング",
                  "summaryText": "ライブ インストラクターの指導のもとに無料のオンライン Dropbox トレーニングを受けることができます。バーチャル クラス設定で、インタラクティブなセッションを通じて新しいスキルを習得できます。\n",
                  "image": {
                    "title": "Learning Live Banner image",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6P5oPiTz2s9uchKdCXrtbj/1b95e452335e29c097137e710ade079c/Learning_Live_Banner_image.svg",
                    "size": 23584,
                    "width": 405,
                    "height": 310,
                    "filename": "Learning Live Banner image.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "2qm2M5L9rHtM93SagsoJ0P",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:26:32.733Z",
                    "internalTitle": "All training - Virtual training - Cards",
                    "title": "ライブ バーチャル トレーニング セッション",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Dropbox ラーニング ライブ バーチャル トレーニング | Dropbox について学ぶ"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "ライブ バーチャル インストラクターによる無料のオンライン Dropbox トレーニングです。小規模なコース環境で Dropbox の実践体験をお楽しみください。今すぐコースにご登録ください。"
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "バーチャルトレーニングセッション",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": "に登録しましょう。",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "completedCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "修了したコースがここに表示されます。",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "inProgressCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "まだ何もありません。",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "75pzFP9abHFb2hlQycUS86",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/self-guided-learning",
                "_modifiedDate": "2025-03-18T22:08:04.576Z",
                "internalTitle": "L2 - Landing page - Self-guided learnings",
                "name": "自分のペースで進められるトレーニング",
                "slug": "self-guided-learning",
                "introHero": {
                  "_id": "6pQlpoBglvzGW38DBWvU5W",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:24:30.062Z",
                  "internalTitle": "Hero - Self-guided learning",
                  "headerText": "自分のペースで進められるトレーニング",
                  "summaryText": "忙しいスケジュールに合うように設計されたコースで Dropbox を使用する方法をご確認ください。\n",
                  "image": {
                    "title": "自分のペースで進められる学習のイラスト",
                    "description": "Dropbox 自分のペースで進められる学習コースの本のイラスト",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6ODZb6bllZrucIYtiLqFkU/f844e955cabc76e34e249d9c18e43d62/self-guided-learning.svg",
                    "size": 42902,
                    "width": 405,
                    "height": 310,
                    "filename": "self-guided-learning.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "6MGcQfM5kOFGpBqiOmSkKy",
                    "_contentTypeId": "moduleFeaturedCourse",
                    "_modifiedDate": "2025-03-18T22:24:36.327Z",
                    "internalTitle": "Featured - Self-guided learning",
                    "title": "注目のコース",
                    "course": null
                  },
                  {
                    "_id": "4JIWsH681XIFek4zmBB6Ix",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:25:12.491Z",
                    "internalTitle": "All courses - Self-guided learning - Cards",
                    "title": "すべてのコース",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Dropbox 自習トレーニング コース | Dropbox について学ぶ"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "自習用の Dropbox のトレーニング コースは、無料およびオンデマンドでご利用いただけます。自分のペースで Dropbox アカウントを使用する方法を学びましょう。今すぐご登録ください。"
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "自分のペースで進められるトレーニングコース",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": "を始めましょう。",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "l18nLookup": {
    "textHr": "時間",
    "textHrs": "時間",
    "textMin": "min",
    "textHour": "時間",
    "textLess": "閉じる",
    "textMins": "分",
    "textMore": "より多く",
    "textRead": "既読",
    "labelTags": "タグ",
    "textClear": "クリア",
    "textHours": "受付時間",
    "textMinute": "分",
    "ctaTextBack": "戻る",
    "ctaTextNext": "次へ",
    "joinUrlText": "セッションに参加",
    "readingTime": "分読むのにかかります",
    "textMinutes": "分",
    "textSeeMore": "もっと表示",
    "textViewAll": "すべて表示",
    "searchHeader": "検索",
    "textComplete": "完了",
    "textError404": "エラー（404）",
    "textFilterBy": "並べ替え",
    "ctaTextLogout": "ログアウト",
    "ctaTextSignIn": "ログイン",
    "labelSubjects": "件名",
    "textNextTopic": "次のモジュール",
    "ctaTextProfile": "プロフィール",
    "ctaTextVirtual": "セッションを表示する",
    "headerDuration": "期間",
    "languageNameDe": "Deutsch",
    "languageNameEs": "Español",
    "languageNameFr": "Français",
    "languageNameIt": "Italiano",
    "languageNameJa": "日本語",
    "ctaTextRegister": "今すぐ登録",
    "labelCategories": "カテゴリ",
    "searchNoResults": "{{search}} の検索結果はありません",
    "textStartCourse": "コースを開始する",
    "textWebinarFull": "このウェビナーは定員に達したため、別の日時をお試しください。",
    "accountLabelLast": "姓",
    "accountLabelName": "名前",
    "ctaCreateAccount": "アカウントを作成",
    "languageNameEnUs": "English",
    "languageNamePtBr": "Português",
    "searchBreadcrumb": "検索結果",
    "searchFilterRole": "役割",
    "textErrorUnknown": "エラーが発生しました",
    "textResumeCourse": "コースを再開する",
    "accountLabelEmail": "メール",
    "ctaTextReschedule": "処理日を変更",
    "ctaTextSelfGuided": "モジュールの表示",
    "ctaTextViewCourse": "コースを表示する",
    "formLabelAttended": "出席",
    "headerAccountInfo": "アカウント情報",
    "textErrorPageHome": "ホーム",
    "textPreviousTopic": "前のモジュール",
    "ctaTextCancelClass": "このクラスをキャンセルする",
    "formInputLabelDate": "日付",
    "headerPersonalInfo": "個人情報",
    "labelSubcategories": "サブカテゴリ",
    "searchResultStatus": "Results {{firstResult}}-{{lastResult}} of {{numResults}} for {{search}}",
    "textBreadcrumbRoot": "ホーム",
    "textCourseProgress": "コースの進捗",
    "formInputLabelEmail": "メール",
    "globalTextCopyright": "著作権テキスト",
    "textDropdownDefault": "時刻はご自身のタイムゾーンに設定されています",
    "textErrorPageSignIn": "ログイン",
    "textTableOfContents": "モジュール",
    "canceledRegistration": "登録がキャンセルされました",
    "formInputLabelCourse": "コース",
    "formInputLabelStatus": "役割",
    "searchFilterLanguage": "言語",
    "searchResultsPerPage": "ページあたりの結果数",
    "textSigninModalIntro": "ログインして進捗を保存するか、スクロールを続けます",
    "topicCountTextPlural": "トピック",
    "topicCountTextSingle": "トピック",
    "headerSuggestedTopics": "推奨モジュール",
    "searchFilterOptionSgc": "自習コース",
    "searchResultTypeTopic": "モジュール",
    "searchResultTypeVideo": "動画",
    "textAlreadyRegistered": "このウェビナーにはすでに登録済みです。",
    "textYouMustLogInFirst": "最初にログインしてください。",
    "formInputLabelAttendee": "参加者",
    "formInputLabelLastName": "姓",
    "formInputLabelOverview": "概要",
    "headerEventDescription": "セッションの説明",
    "headerProfileCompleted": "修了したコース",
    "searchFilterOptionLive": "ライブ セッション",
    "searchFilterOptionTeam": "チーム メンバー",
    "textCourseExpectations": "変更内容：",
    "textLearningObjectives": "コース内容",
    "formInputLabelFirstName": "名",
    "headerProfileInProgress": "進行中のコース",
    "pageVideoCategoriesSync": "同期",
    "searchFilterContentType": "コンテンツタイプ",
    "searchFilterOptionAdmin": "管理者",
    "searchFilterOptionTopic": "モジュール",
    "searchPageTitleMetadata": "検索 | Dropbox について学ぶ",
    "textErrorPageHelpCenter": "ヘルプセンター",
    "formInputLabelSelectDate": "日付を選択してください",
    "formInputLabelSelectTime": "時間を選択してください",
    "headerProfileLiveCourses": "今後のコース",
    "pageVideoCategoriesShare": "共有",
    "formInputLabelSessionLink": "セッション リンク",
    "textErrorPageHelpfulLinks": "役立つリンク：",
    "textErrorPagePageNotFound": "お探しのページが見つかりません",
    "textRegistrationCompleted": "登録しました",
    "breadcrumbTextRegistration": "登録",
    "formInputLabelPickLanguage": "言語を選択してください",
    "pageVideoCategoriesWebinar": "ウェビナー",
    "searchPageKeywordsMetadata": "検索",
    "searchResultTypeSelfGuided": "コース",
    "ctaTextCompleteRegistration": "登録を完了してください",
    "headerEnterRegistrationInfo": "登録情報を入力してください",
    "headerRegisteredLiveCourses": "登録済みバーチャル トレーニング",
    "pageVideoCategoriesInstalls": "インストール",
    "pageVideoCategoriesOrganize": "整理",
    "pageVideoCategoriesProducts": "製品",
    "pageVideoCategoriesSecurity": "セキュリティ",
    "searchFilterOptionRoleAdmin": "管理者",
    "searchResultTypeLiveSession": "ライブ セッション",
    "headerRegistrationSuccessful": "正常に登録されました",
    "headerSearchInputPlaceholder": "検索",
    "pageVideoSubcategoriesDelete": "削除",
    "pageVideoSubcategoriesReplay": "Replay",
    "pageVideoSubcategoriesRewind": "巻き戻す",
    "pageVideoSubcategoriesSignIn": "ログイン",
    "searchFilterOptionIndividual": "個人用",
    "formInputLabelCourseCompleted": "コース修了",
    "pageVideoSubcategoriesCapture": "Capture",
    "pageVideoSubcategoriesRestore": "復元",
    "searchPageDescriptionMetadata": "モジュールなどの情報を検索して、Dropbox のコースに関する詳細をご覧いただけます。ご自分のニーズに最適な Dropbox トレーニングまたはセルフ ガイド コースを見つけてください。",
    "pageVideoCategoriesViewAndEdit": "閲覧と編集",
    "pageVideoSubcategoriesTransfer": "Transfer",
    "searchFilterOptionRoleHelpDesk": "ヘルプ デスク",
    "textCalendarShortWeekdayFriday": "金",
    "textCalendarShortWeekdayMonday": "月",
    "textCalendarShortWeekdaySunday": "日",
    "pageVideoSubcategoriesMobileApp": "モバイル アプリ",
    "textCalendarShortWeekdayTuesday": "火",
    "textEmailConfirmationWillBeSent": "受信トレイで確認メールをチェックしてください。",
    "headerLiveCourseHighlightsHeader": "次の方法を学習します。",
    "pageVideoCategoriesAccountAccess": "アカウントのアクセス",
    "pageVideoSubcategoriesTeamAccess": "チームのアクセス権",
    "searchFilterOptionRoleTeamMember": "チーム メンバー",
    "textCalendarShortWeekdaySaturday": "土",
    "textCalendarShortWeekdayThursday": "木",
    "formInputLabelGeneralRegistration": "一般登録",
    "pageVideoSubcategoriesTeamFolders": "チーム フォルダ",
    "textCalendarShortWeekdayWednesday": "水",
    "formInputLabelGlobalSelectLanguage": "言語を選択してください",
    "formInputLabelSelectDateBreadcrumb": "日付を選択",
    "pageVideoCategoriesAccountSettings": "アカウント設定",
    "pageVideoCategoriesCreateAndUpload": "作成とアップロード",
    "pageVideoSubcategoriesTeamSecurity": "チームのセキュリティ",
    "searchFilterOptionRoleFundamentals": "基礎",
    "pageVideoCategoriesDeleteAndRestore": "削除と復元",
    "pageCourseTopicTableOfContentsHeading": "ここからの項目：",
    "pageVideoSubcategoriesDropboxPolicies": "Dropbox のポリシー",
    "pageVideoSubcategoriesCommentingOnFiles": "ファイルにコメントする",
    "pageVideoSubcategoriesSharingPermissions": "共有権限",
    "pageVideoSubcategoriesDropboxApplications": "Dropbox のアプリケーション",
    "pageVideoSubcategoriesSearchingYourAccount": "アカウント内の検索",
    "pageVideoSubcategoriesManagingAccountAccess": "アカウントへのアクセスを管理",
    "pageVideoSubcategoriesSharingFilesAndFolders": "ファイルやフォルダを共有する",
    "pageVideoSubcategoriesCreatingFilesAndFolders": "ファイルやフォルダの作成"
  },
  "copyrightText": "Dropbox 2021",
  "seo": {
    "title": {
      "name": "title",
      "value": "Dropbox learn"
    },
    "keywords": {
      "name": "keywords",
      "value": ""
    },
    "og:image": {
      "name": "og:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "og:title": {
      "name": "og:title",
      "value": "Dropbox learn"
    },
    "canonical": {
      "name": "canonical",
      "value": ""
    },
    "description": {
      "name": "description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:image": {
      "name": "twitter:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "twitter:title": {
      "name": "twitter:title",
      "value": "Dropbox learn"
    },
    "og:description": {
      "name": "og:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:description": {
      "name": "twitter:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    }
  },
  "seoOrganizationsSchema": {
    "url": "http://www.dropbox.com",
    "@type": "Organization",
    "@context": "http://schema.org",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": "TollFree"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "contactType": "customer service"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": [
          "HearingImpairedSupported",
          "TollFree"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "technical support",
        "contactOption": "TollFree",
        "availableLanguage": [
          "English",
          "French"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "bill payment",
        "contactOption": "TollFree"
      }
    ]
  }
}
  },
  "pt-BR": {
    isDefault: false,
    settings: {
  "_id": "4KRGLSrqTRY6s18UzHe1II",
  "_contentTypeId": "settingsGlobal",
  "_modifiedDate": "2024-10-08T22:02:32.138Z",
  "internalTitle": "CX Learning Hub Global Settings",
  "topNavigationLogo": {
    "title": "Dropbox Logo",
    "url": "//images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
    "size": 3707,
    "width": 324,
    "height": 64,
    "filename": "Dropbox.svg",
    "contentType": "image/svg+xml"
  },
  "mainNavigation": [
    {
      "internalTitle": "Supernav - Dropbox Help Center",
      "linkText": "Central de ajuda",
      "destinationType": "Manual text entry",
      "_id": "5pPOxJP1W9PaPBCnLHhe5x",
      "_contentTypeId": "elementLink",
      "href": "https://help.dropbox.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Community",
      "linkText": "Comunidade",
      "destinationType": "Manual text entry",
      "_id": "7aVH719lug9lYEJzIntabM",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropboxforum.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox learn",
      "linkText": "Aprenda sobre o Dropbox",
      "destinationType": "Content reference",
      "_id": "2EPydjcpXLep5NbdcRq3ca",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/home",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Contact us",
      "linkText": "Entrar em contato com o atendimento",
      "destinationType": "Manual text entry",
      "_id": "5v3vwPdKulQu6xhA6csIT8",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropbox.com/support",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    }
  ],
  "secondaryNavigation": [
    {
      "internalTitle": "Secondary Nav - Self-guided learning",
      "linkText": "Aprendizado autoguiado",
      "destinationType": "Content reference",
      "_id": "oMqs4whMJuBjhZsbMv0KA",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/self-guided-learning",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Virtual training",
      "linkText": "Treinamento virtual",
      "destinationType": "Content reference",
      "_id": "31NB5ThxIWIxIju5dMFYC5",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/virtual-training",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Videos Landing Page",
      "linkText": "Vídeos",
      "destinationType": "Content reference",
      "_id": "5y8ArqrhCZ6DoCCGxaumhI",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/video-library",
      "target": null,
      "isModal": false,
      "download": false
    }
  ],
  "footerNavigation": [
    {
      "_id": "1M79uBMmyuD9Iw6fuOdVEJ",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:11:07.049Z",
      "internalTitle": "Dropbox",
      "links": [
        {
          "internalTitle": "Footer Link - Desktop app",
          "linkText": "Aplicativo de desktop",
          "destinationType": "Manual text entry",
          "_id": "3SKeLjF7MWEq2FqaRwnmiK",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/install?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Mobile apps",
          "linkText": "Aplicativos para dispositivos móveis",
          "destinationType": "Manual text entry",
          "_id": "62ymoNSBbWiYinBkFO0cGj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/mobile?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Plans",
          "linkText": "Planos",
          "destinationType": "Manual text entry",
          "_id": "3Fdgsos4Pm77l2C4hKm2AC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plans?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Security",
          "linkText": "Segurança",
          "destinationType": "Manual text entry",
          "_id": "2YZlyLOZLw2lauy2OWiuB2",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/security?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Features",
          "linkText": "Recursos",
          "destinationType": "Manual text entry",
          "_id": "wUuQPtOLYz96QOSvtgLTh",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/features?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "6LXqBrZEH3p2eDbBpiPFlm",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:40.613Z",
      "internalTitle": "Produtos",
      "links": [
        {
          "internalTitle": "Footer Links - Plus",
          "linkText": "Plus",
          "destinationType": "Manual text entry",
          "_id": "4nThAv7YjSuKC91UBK1AoR",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plus?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links -  Professional",
          "linkText": "Professional",
          "destinationType": "Manual text entry",
          "_id": "5YtdEKxrCJgHqmmDvwgnQI",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/pro?trigger=_footer",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Business",
          "linkText": "Negócios",
          "destinationType": "Manual text entry",
          "_id": "5L4PFaY36Icmt95mUTgjOC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/business?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Enterprise",
          "linkText": "Enterprise",
          "destinationType": "Manual text entry",
          "_id": "4KJcgnikVfGiWIaFiuiRIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/enterprise?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "5xRp5jjLsl92vmGL0qJfVb",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:03:26.918Z",
      "internalTitle": "Empresa",
      "links": [
        {
          "internalTitle": "Footer Link - About us",
          "linkText": "Sobre nós",
          "destinationType": "Manual text entry",
          "_id": "6XnBD8OrJfQZ4u3ZSc6ysr",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/about",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Jobs",
          "linkText": "Trabalhe conosco",
          "destinationType": "Manual text entry",
          "_id": "1nmf2X1QaLv7zS1PIyfqlE",
          "_contentTypeId": "elementLink",
          "href": "https://jobs.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Press",
          "linkText": "Mídia",
          "destinationType": "Manual text entry",
          "_id": "5iMgmL64RXCXtz8iRkrIIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/news?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Investor relations",
          "linkText": "Relações com investidores",
          "destinationType": "Manual text entry",
          "_id": "73WTKUyXSmqc5aP1OfisZ9",
          "_contentTypeId": "elementLink",
          "href": "https://investors.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Blog",
          "linkText": "Blog",
          "destinationType": "Manual text entry",
          "_id": "4mTcqsVCIHytbISiyJordW",
          "_contentTypeId": "elementLink",
          "href": "https://blog.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "1TnZWWGdwo9BEkEKgcIGpA",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:07:49.392Z",
      "internalTitle": "Atendimento",
      "links": [
        {
          "internalTitle": "Supernav - Dropbox Help Center",
          "linkText": "Central de ajuda",
          "destinationType": "Manual text entry",
          "_id": "5pPOxJP1W9PaPBCnLHhe5x",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Supernav - Dropbox Contact us",
          "linkText": "Entrar em contato com o atendimento",
          "destinationType": "Manual text entry",
          "_id": "5v3vwPdKulQu6xhA6csIT8",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/support",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Cookie Policy",
          "linkText": "Política de cookies",
          "destinationType": "Manual text entry",
          "_id": "3akG8gNhd73ZlRaEYSCvUD",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/security/cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Manage Cookies",
          "linkText": "Gerenciar cookies",
          "destinationType": "Manual text entry",
          "_id": "65mwIIQg9xKeyKiICuy9DJ",
          "_contentTypeId": "elementLink",
          "href": "#manage-cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Privacy & terms",
          "linkText": "Privacidade e Termos de uso",
          "destinationType": "Manual text entry",
          "_id": "30MJJnJ2DIcvhYL2wsg0FB",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/privacy?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Sitemap",
          "linkText": "Mapa do site",
          "destinationType": "Manual text entry",
          "_id": "37byNy2Pux5TAcdSJm6g4f",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/sitemap?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "28o2jcc5DrafqCYZ9icYU7",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:42.647Z",
      "internalTitle": "Comunidade",
      "links": [
        {
          "internalTitle": "Footer Links - Developers",
          "linkText": "Desenvolvedores",
          "destinationType": "Manual text entry",
          "_id": "2aFb3GcSJS0YIEU3aKbLzj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/developers?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Referrals",
          "linkText": "Indicações",
          "destinationType": "Manual text entry",
          "_id": "4YsKqwQfAG4Ds0a0yeo9wp",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/referrals?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Forum",
          "linkText": "Fórum",
          "destinationType": "Manual text entry",
          "_id": "4IGtm6twVWzfoE07kHlucc",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropboxforum.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    }
  ],
  "cookieBarBody": "Usamos cookies para que o Dropbox funcione melhor para você. Ao usar nosso site, você concorda com nossa utilização de cookies.",
  "cookieBarCta": {
    "internalTitle": "Link Text - Cookie bar",
    "linkText": "Saiba mais",
    "destinationType": "Manual text entry",
    "_id": "1tY3PGTzdIHKRi4pDNbmSM",
    "_contentTypeId": "elementLink",
    "href": "https://help.dropbox.com/accounts-billing/security/cookies",
    "as": null,
    "target": "_blank",
    "isModal": false,
    "download": false
  },
  "cookieBarEnabled": true,
  "alertBarCta": null,
  "virtualTrainingsEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Nada para ver ainda. Registre‑se em uma ",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "H59akjvvDv1tG26hXdjcK",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/virtual-training",
                "_modifiedDate": "2025-03-18T22:08:03.392Z",
                "internalTitle": "L2 - Landing page - Virtual training",
                "name": "Treinamento virtual do Dropbox",
                "slug": "virtual-training",
                "introHero": {
                  "_id": "5Hy71DzB4jMPIMzXg2PFmC",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:26:18.543Z",
                  "internalTitle": "Hero - Virtual training",
                  "headerText": "Treinamento virtual do Dropbox",
                  "summaryText": "Obtenha treinamento on-line gratuito do Dropbox com um instrutor ao vivo. Aprenda novas habilidades através de sessões interativas em um ambiente de sala de aula virtual.\n",
                  "image": {
                    "title": "Learning Live Banner image",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6P5oPiTz2s9uchKdCXrtbj/1b95e452335e29c097137e710ade079c/Learning_Live_Banner_image.svg",
                    "size": 23584,
                    "width": 405,
                    "height": 310,
                    "filename": "Learning Live Banner image.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "2qm2M5L9rHtM93SagsoJ0P",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:26:32.733Z",
                    "internalTitle": "All training - Virtual training - Cards",
                    "title": "Sessões de treinamento virtual ao vivo",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Treinamento virtual ao vivo de aprendizado do Dropbox | Aprenda sobre o Dropbox"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "Treinamento on-line gratuito do Dropbox com instrutor ao vivo. Aprenda na prática a usar o Dropbox no ambiente de um curso compacto. Inscreva-se em um curso hoje mesmo."
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "sessão de treinamento virtual",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": ".",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "completedCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Os cursos que você completou aparecerão aqui.",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "inProgressCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Nada para ver ainda. Inicie um ",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "75pzFP9abHFb2hlQycUS86",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/self-guided-learning",
                "_modifiedDate": "2025-03-18T22:08:04.576Z",
                "internalTitle": "L2 - Landing page - Self-guided learnings",
                "name": "Aprendizado autoguiado",
                "slug": "self-guided-learning",
                "introHero": {
                  "_id": "6pQlpoBglvzGW38DBWvU5W",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:24:30.062Z",
                  "internalTitle": "Hero - Self-guided learning",
                  "headerText": "Aprendizado autoguiado",
                  "summaryText": "Saiba como usar o Dropbox em cursos desenvolvidos para se ajustar à sua agenda cheia.\n",
                  "image": {
                    "title": "Ilustração de aprendizagem autoguiada",
                    "description": "Ilustração de livro para cursos autoguiados do Dropbox",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6ODZb6bllZrucIYtiLqFkU/f844e955cabc76e34e249d9c18e43d62/self-guided-learning.svg",
                    "size": 42902,
                    "width": 405,
                    "height": 310,
                    "filename": "self-guided-learning.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "6MGcQfM5kOFGpBqiOmSkKy",
                    "_contentTypeId": "moduleFeaturedCourse",
                    "_modifiedDate": "2025-03-18T22:24:36.327Z",
                    "internalTitle": "Featured - Self-guided learning",
                    "title": "Curso em destaque",
                    "course": null
                  },
                  {
                    "_id": "4JIWsH681XIFek4zmBB6Ix",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:25:12.491Z",
                    "internalTitle": "All courses - Self-guided learning - Cards",
                    "title": "Todos os cursos",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Cursos de treinamento autoguiado do Dropbox | Aprenda sobre o Dropbox"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "Cursos individualizados de treinamento do Dropbox, disponíveis gratuitamente e sob demanda. Aprenda a usar sua conta do Dropbox no seu próprio ritmo. Inscreva-se hoje mesmo."
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "curso de aprendizado autoguiado",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": ".",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "l18nLookup": {
    "textHr": "h",
    "textHrs": "h",
    "textMin": "mín",
    "textHour": "hora",
    "textLess": "Menos",
    "textMins": "mins",
    "textMore": "Mais",
    "textRead": "Ler",
    "labelTags": "Tags",
    "textClear": "Limpar",
    "textHours": "Horários",
    "textMinute": "minuto",
    "ctaTextBack": "Voltar",
    "ctaTextNext": "Seguinte",
    "joinUrlText": "Participar da sessão",
    "readingTime": "Leitura de minutos",
    "textMinutes": "minutos",
    "textSeeMore": "Ver mais",
    "textViewAll": "Visualizar tudo",
    "searchHeader": "Buscar",
    "textComplete": "Concluído",
    "textError404": "Erro (404)",
    "textFilterBy": "Filtrar por",
    "ctaTextLogout": "Sair",
    "ctaTextSignIn": "Acessar",
    "labelSubjects": "Assunto",
    "textNextTopic": "Próximo módulo",
    "ctaTextProfile": "Perfil",
    "ctaTextVirtual": "Visualizar sessão",
    "headerDuration": "Duração",
    "languageNameDe": "Deutsch",
    "languageNameEs": "Español",
    "languageNameFr": "Français",
    "languageNameIt": "Italiano",
    "languageNameJa": "日本語",
    "ctaTextRegister": "Registrar agora",
    "labelCategories": "Categorias",
    "searchNoResults": "Nenhum resultado para {{search}}",
    "textStartCourse": "Iniciar curso",
    "textWebinarFull": "Este webinar está lotado. Tente um outro dia ou horário.",
    "accountLabelLast": "Sobrenome",
    "accountLabelName": "Nome",
    "ctaCreateAccount": "Criar conta",
    "languageNameEnUs": "English",
    "languageNamePtBr": "Português",
    "searchBreadcrumb": "Resultados da busca",
    "searchFilterRole": "Função",
    "textErrorUnknown": "Algo deu errado",
    "textResumeCourse": "Retomar o curso",
    "accountLabelEmail": "E-mail",
    "ctaTextReschedule": "Reagendar",
    "ctaTextSelfGuided": "Ver módulos",
    "ctaTextViewCourse": "Ver curso",
    "formLabelAttended": "Assistido",
    "headerAccountInfo": "Informações da conta",
    "textErrorPageHome": "Início",
    "textPreviousTopic": "Módulo anterior",
    "ctaTextCancelClass": "Cancelar esta aula",
    "formInputLabelDate": "Data",
    "headerPersonalInfo": "Informações pessoais",
    "labelSubcategories": "Subcategorias",
    "searchResultStatus": "Results {{firstResult}}-{{lastResult}} of {{numResults}} for {{search}}",
    "textBreadcrumbRoot": "Início",
    "textCourseProgress": "Progresso do curso",
    "formInputLabelEmail": "E-mail",
    "globalTextCopyright": "Texto de copyright",
    "textDropdownDefault": "Os horários estão configurados de acordo com o seu fuso horário",
    "textErrorPageSignIn": "Acessar",
    "textTableOfContents": "Módulos",
    "canceledRegistration": "Registro cancelado com sucesso",
    "formInputLabelCourse": "Curso",
    "formInputLabelStatus": "Status",
    "searchFilterLanguage": "Idioma",
    "searchResultsPerPage": "Resultados por página",
    "textSigninModalIntro": "Acesse sua conta para salvar seu progresso ou continuar navegando",
    "topicCountTextPlural": "Tópicos",
    "topicCountTextSingle": "Tópico",
    "headerSuggestedTopics": "Módulos sugeridos",
    "searchFilterOptionSgc": "Curso individualizado",
    "searchResultTypeTopic": "Módulo",
    "searchResultTypeVideo": "Vídeo",
    "textAlreadyRegistered": "Você já se registrou neste webinar.",
    "textYouMustLogInFirst": "É preciso acessar a conta primeiro",
    "formInputLabelAttendee": "Participante",
    "formInputLabelLastName": "Sobrenome",
    "formInputLabelOverview": "Visão geral",
    "headerEventDescription": "Descrição da sessão",
    "headerProfileCompleted": "Cursos concluídos",
    "searchFilterOptionLive": "Sessão ao vivo",
    "searchFilterOptionTeam": "Membros da equipe",
    "textCourseExpectations": "O que esperar:",
    "textLearningObjectives": "O que você vai aprender",
    "formInputLabelFirstName": "Nome",
    "headerProfileInProgress": "Cursos em andamento",
    "pageVideoCategoriesSync": "Sincronização",
    "searchFilterContentType": "Tipo de conteúdo",
    "searchFilterOptionAdmin": "Administrador",
    "searchFilterOptionTopic": "Módulo",
    "searchPageTitleMetadata": "Busca | Aprenda sobre o Dropbox",
    "textErrorPageHelpCenter": "Central de ajuda",
    "formInputLabelSelectDate": "Selecione uma data",
    "formInputLabelSelectTime": "Selecione um horário",
    "headerProfileLiveCourses": "Próximos cursos",
    "pageVideoCategoriesShare": "Compartilhar",
    "formInputLabelSessionLink": "Link da sessão",
    "textErrorPageHelpfulLinks": "Aqui estão alguns links úteis:",
    "textErrorPagePageNotFound": "Não foi possível encontrar a página que você está procurando",
    "textRegistrationCompleted": "Você está inscrito",
    "breadcrumbTextRegistration": "Inscrição",
    "formInputLabelPickLanguage": "Selecione seu idioma",
    "pageVideoCategoriesWebinar": "Webinar",
    "searchPageKeywordsMetadata": "buscar",
    "searchResultTypeSelfGuided": "Curso",
    "ctaTextCompleteRegistration": "Conclua sua inscrição",
    "headerEnterRegistrationInfo": "Insira suas informações de inscrição",
    "headerRegisteredLiveCourses": "Treinamentos virtuais cadastrados",
    "pageVideoCategoriesInstalls": "Instalar",
    "pageVideoCategoriesOrganize": "Organizar",
    "pageVideoCategoriesProducts": "Produtos",
    "pageVideoCategoriesSecurity": "Segurança",
    "searchFilterOptionRoleAdmin": "Administrador",
    "searchResultTypeLiveSession": "Sessão ao vivo",
    "headerRegistrationSuccessful": "Você se inscreveu com sucesso",
    "headerSearchInputPlaceholder": "Buscar",
    "pageVideoSubcategoriesDelete": "Excluir",
    "pageVideoSubcategoriesReplay": "Replay",
    "pageVideoSubcategoriesRewind": "Retroceder",
    "pageVideoSubcategoriesSignIn": "Acessar",
    "searchFilterOptionIndividual": "Individual",
    "formInputLabelCourseCompleted": "Curso concluído",
    "pageVideoSubcategoriesCapture": "Capture",
    "pageVideoSubcategoriesRestore": "Restaurar",
    "searchPageDescriptionMetadata": "Você pode procurar módulos ou informações para saber mais sobre os nossos cursos. Encontre a aprendizagem autoguiada ou o treinamento mais adequado às suas necessidades.",
    "pageVideoCategoriesViewAndEdit": "Visualizar e editar",
    "pageVideoSubcategoriesTransfer": "Transfer",
    "searchFilterOptionRoleHelpDesk": "Serviço de ajuda",
    "textCalendarShortWeekdayFriday": "sex",
    "textCalendarShortWeekdayMonday": "seg",
    "textCalendarShortWeekdaySunday": "dom",
    "pageVideoSubcategoriesMobileApp": "Aplicativo móvel",
    "textCalendarShortWeekdayTuesday": "ter",
    "textEmailConfirmationWillBeSent": "Verifique se recebeu um e-mail de confirmação em sua caixa de entrada.",
    "headerLiveCourseHighlightsHeader": "Você aprenderá a:",
    "pageVideoCategoriesAccountAccess": "Acesso à conta",
    "pageVideoSubcategoriesTeamAccess": "Acesso da equipe",
    "searchFilterOptionRoleTeamMember": "Membros da equipe",
    "textCalendarShortWeekdaySaturday": "sáb",
    "textCalendarShortWeekdayThursday": "qui",
    "formInputLabelGeneralRegistration": "Inscrição geral",
    "pageVideoSubcategoriesTeamFolders": "Pastas da equipe",
    "textCalendarShortWeekdayWednesday": "qua",
    "formInputLabelGlobalSelectLanguage": "Selecione um idioma",
    "formInputLabelSelectDateBreadcrumb": "Selecionar data",
    "pageVideoCategoriesAccountSettings": "Configurações da conta",
    "pageVideoCategoriesCreateAndUpload": "Criar e enviar",
    "pageVideoSubcategoriesTeamSecurity": "Segurança da equipe",
    "searchFilterOptionRoleFundamentals": "Básico",
    "pageVideoCategoriesDeleteAndRestore": "Excluir e restaurar",
    "pageCourseTopicTableOfContentsHeading": "Seções deste artigo:",
    "pageVideoSubcategoriesDropboxPolicies": "Políticas do Dropbox",
    "pageVideoSubcategoriesCommentingOnFiles": "Comentários nos arquivos",
    "pageVideoSubcategoriesSharingPermissions": "Permissões de compartilhamento",
    "pageVideoSubcategoriesDropboxApplications": "Aplicativos do Dropbox",
    "pageVideoSubcategoriesSearchingYourAccount": "Buscar na sua conta",
    "pageVideoSubcategoriesManagingAccountAccess": "Gerenciamento do acesso à conta",
    "pageVideoSubcategoriesSharingFilesAndFolders": "Compartilhando arquivos e pastas",
    "pageVideoSubcategoriesCreatingFilesAndFolders": "Como criar arquivos e pastas"
  },
  "copyrightText": "Dropbox 2021",
  "seo": {
    "title": {
      "name": "title",
      "value": "Dropbox learn"
    },
    "keywords": {
      "name": "keywords",
      "value": ""
    },
    "og:image": {
      "name": "og:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "og:title": {
      "name": "og:title",
      "value": "Dropbox learn"
    },
    "canonical": {
      "name": "canonical",
      "value": ""
    },
    "description": {
      "name": "description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:image": {
      "name": "twitter:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "twitter:title": {
      "name": "twitter:title",
      "value": "Dropbox learn"
    },
    "og:description": {
      "name": "og:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:description": {
      "name": "twitter:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    }
  },
  "seoOrganizationsSchema": {
    "url": "http://www.dropbox.com",
    "@type": "Organization",
    "@context": "http://schema.org",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": "TollFree"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "contactType": "customer service"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": [
          "HearingImpairedSupported",
          "TollFree"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "technical support",
        "contactOption": "TollFree",
        "availableLanguage": [
          "English",
          "French"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "bill payment",
        "contactOption": "TollFree"
      }
    ]
  }
}
  },
  "es": {
    isDefault: false,
    settings: {
  "_id": "4KRGLSrqTRY6s18UzHe1II",
  "_contentTypeId": "settingsGlobal",
  "_modifiedDate": "2024-10-08T22:02:32.138Z",
  "internalTitle": "CX Learning Hub Global Settings",
  "topNavigationLogo": {
    "title": "Dropbox Logo",
    "url": "//images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
    "size": 3707,
    "width": 324,
    "height": 64,
    "filename": "Dropbox.svg",
    "contentType": "image/svg+xml"
  },
  "mainNavigation": [
    {
      "internalTitle": "Supernav - Dropbox Help Center",
      "linkText": "Centro de ayuda",
      "destinationType": "Manual text entry",
      "_id": "5pPOxJP1W9PaPBCnLHhe5x",
      "_contentTypeId": "elementLink",
      "href": "https://help.dropbox.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Community",
      "linkText": "Comunidad",
      "destinationType": "Manual text entry",
      "_id": "7aVH719lug9lYEJzIntabM",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropboxforum.com/",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox learn",
      "linkText": "Formación de Dropbox",
      "destinationType": "Content reference",
      "_id": "2EPydjcpXLep5NbdcRq3ca",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/home",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Supernav - Dropbox Contact us",
      "linkText": "Contactar con asistencia",
      "destinationType": "Manual text entry",
      "_id": "5v3vwPdKulQu6xhA6csIT8",
      "_contentTypeId": "elementLink",
      "href": "https://www.dropbox.com/support",
      "as": null,
      "target": "_blank",
      "isModal": false,
      "download": false
    }
  ],
  "secondaryNavigation": [
    {
      "internalTitle": "Secondary Nav - Self-guided learning",
      "linkText": "Aprendizaje autodirigido",
      "destinationType": "Content reference",
      "_id": "oMqs4whMJuBjhZsbMv0KA",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/self-guided-learning",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Virtual training",
      "linkText": "Formación virtual",
      "destinationType": "Content reference",
      "_id": "31NB5ThxIWIxIju5dMFYC5",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/virtual-training",
      "target": null,
      "isModal": false,
      "download": false
    },
    {
      "internalTitle": "Secondary Nav - Videos Landing Page",
      "linkText": "Vídeos",
      "destinationType": "Content reference",
      "_id": "5y8ArqrhCZ6DoCCGxaumhI",
      "_contentTypeId": "elementLink",
      "href": "/[slug]",
      "as": "/video-library",
      "target": null,
      "isModal": false,
      "download": false
    }
  ],
  "footerNavigation": [
    {
      "_id": "1M79uBMmyuD9Iw6fuOdVEJ",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:11:07.049Z",
      "internalTitle": "Dropbox",
      "links": [
        {
          "internalTitle": "Footer Link - Desktop app",
          "linkText": "Aplicación de escritorio",
          "destinationType": "Manual text entry",
          "_id": "3SKeLjF7MWEq2FqaRwnmiK",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/install?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Mobile apps",
          "linkText": "Aplicaciones para móviles",
          "destinationType": "Manual text entry",
          "_id": "62ymoNSBbWiYinBkFO0cGj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/mobile?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Plans",
          "linkText": "Planes",
          "destinationType": "Manual text entry",
          "_id": "3Fdgsos4Pm77l2C4hKm2AC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plans?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Security",
          "linkText": "Seguridad",
          "destinationType": "Manual text entry",
          "_id": "2YZlyLOZLw2lauy2OWiuB2",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/security?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Features",
          "linkText": "Funciones",
          "destinationType": "Manual text entry",
          "_id": "wUuQPtOLYz96QOSvtgLTh",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/features?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "6LXqBrZEH3p2eDbBpiPFlm",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:40.613Z",
      "internalTitle": "Productos",
      "links": [
        {
          "internalTitle": "Footer Links - Plus",
          "linkText": "Plus",
          "destinationType": "Manual text entry",
          "_id": "4nThAv7YjSuKC91UBK1AoR",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/plus?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links -  Professional",
          "linkText": "Professional",
          "destinationType": "Manual text entry",
          "_id": "5YtdEKxrCJgHqmmDvwgnQI",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/pro?trigger=_footer",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Business",
          "linkText": "Empresa",
          "destinationType": "Manual text entry",
          "_id": "5L4PFaY36Icmt95mUTgjOC",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/business?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Enterprise",
          "linkText": "Enterprise",
          "destinationType": "Manual text entry",
          "_id": "4KJcgnikVfGiWIaFiuiRIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/enterprise?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "5xRp5jjLsl92vmGL0qJfVb",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:03:26.918Z",
      "internalTitle": "Empresa",
      "links": [
        {
          "internalTitle": "Footer Link - About us",
          "linkText": "Sobre nosotros",
          "destinationType": "Manual text entry",
          "_id": "6XnBD8OrJfQZ4u3ZSc6ysr",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/about",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Jobs",
          "linkText": "Jobs",
          "destinationType": "Manual text entry",
          "_id": "1nmf2X1QaLv7zS1PIyfqlE",
          "_contentTypeId": "elementLink",
          "href": "https://jobs.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Press",
          "linkText": "Prensa",
          "destinationType": "Manual text entry",
          "_id": "5iMgmL64RXCXtz8iRkrIIM",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/news?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link -  Investor relations",
          "linkText": "Relaciones con inversores",
          "destinationType": "Manual text entry",
          "_id": "73WTKUyXSmqc5aP1OfisZ9",
          "_contentTypeId": "elementLink",
          "href": "https://investors.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Link - Blog",
          "linkText": "Blog",
          "destinationType": "Manual text entry",
          "_id": "4mTcqsVCIHytbISiyJordW",
          "_contentTypeId": "elementLink",
          "href": "https://blog.dropbox.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "1TnZWWGdwo9BEkEKgcIGpA",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:07:49.392Z",
      "internalTitle": "Asistencia",
      "links": [
        {
          "internalTitle": "Supernav - Dropbox Help Center",
          "linkText": "Centro de ayuda",
          "destinationType": "Manual text entry",
          "_id": "5pPOxJP1W9PaPBCnLHhe5x",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Supernav - Dropbox Contact us",
          "linkText": "Contactar con asistencia",
          "destinationType": "Manual text entry",
          "_id": "5v3vwPdKulQu6xhA6csIT8",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/support",
          "as": null,
          "target": "_blank",
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Cookie Policy",
          "linkText": "Política de cookies",
          "destinationType": "Manual text entry",
          "_id": "3akG8gNhd73ZlRaEYSCvUD",
          "_contentTypeId": "elementLink",
          "href": "https://help.dropbox.com/security/cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Manage Cookies",
          "linkText": "Gestionar cookies",
          "destinationType": "Manual text entry",
          "_id": "65mwIIQg9xKeyKiICuy9DJ",
          "_contentTypeId": "elementLink",
          "href": "#manage-cookies",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Privacy & terms",
          "linkText": "Privacidad y condiciones",
          "destinationType": "Manual text entry",
          "_id": "30MJJnJ2DIcvhYL2wsg0FB",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/privacy?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Sitemap",
          "linkText": "Mapa del sitio",
          "destinationType": "Manual text entry",
          "_id": "37byNy2Pux5TAcdSJm6g4f",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/sitemap?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    },
    {
      "_id": "28o2jcc5DrafqCYZ9icYU7",
      "_contentTypeId": "elementFooterNavColumn",
      "_modifiedDate": "2024-09-30T17:08:42.647Z",
      "internalTitle": "Comunidad",
      "links": [
        {
          "internalTitle": "Footer Links - Developers",
          "linkText": "Developers",
          "destinationType": "Manual text entry",
          "_id": "2aFb3GcSJS0YIEU3aKbLzj",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/developers?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Referrals",
          "linkText": "Recomendaciones",
          "destinationType": "Manual text entry",
          "_id": "4YsKqwQfAG4Ds0a0yeo9wp",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropbox.com/referrals?trigger=_footer",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        },
        {
          "internalTitle": "Footer Links - Forum",
          "linkText": "Foro",
          "destinationType": "Manual text entry",
          "_id": "4IGtm6twVWzfoE07kHlucc",
          "_contentTypeId": "elementLink",
          "href": "https://www.dropboxforum.com/",
          "as": null,
          "target": null,
          "isModal": false,
          "download": false
        }
      ]
    }
  ],
  "cookieBarBody": "Utilizamos las cookies para que Dropbox funcione. Al utilizar nuestro sitio web, aceptas nuestro uso de cookies.",
  "cookieBarCta": {
    "internalTitle": "Link Text - Cookie bar",
    "linkText": "Más información",
    "destinationType": "Manual text entry",
    "_id": "1tY3PGTzdIHKRi4pDNbmSM",
    "_contentTypeId": "elementLink",
    "href": "https://help.dropbox.com/accounts-billing/security/cookies",
    "as": null,
    "target": "_blank",
    "isModal": false,
    "download": false
  },
  "cookieBarEnabled": true,
  "alertBarCta": null,
  "virtualTrainingsEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Aún no hay nada que ver. Regístrate para acceder a una ",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "H59akjvvDv1tG26hXdjcK",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/virtual-training",
                "_modifiedDate": "2025-03-18T22:08:03.392Z",
                "internalTitle": "L2 - Landing page - Virtual training",
                "name": "Formación virtual de Dropbox",
                "slug": "virtual-training",
                "introHero": {
                  "_id": "5Hy71DzB4jMPIMzXg2PFmC",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:26:18.543Z",
                  "internalTitle": "Hero - Virtual training",
                  "headerText": "Formación virtual de Dropbox",
                  "summaryText": "Sigue una formación gratuita de Dropbox en línea con un instructor en vivo. Obtén nuevos conocimientos a través de sesiones interactivas en un entorno de aula virtual.\n",
                  "image": {
                    "title": "Learning Live Banner image",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6P5oPiTz2s9uchKdCXrtbj/1b95e452335e29c097137e710ade079c/Learning_Live_Banner_image.svg",
                    "size": 23584,
                    "width": 405,
                    "height": 310,
                    "filename": "Learning Live Banner image.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "2qm2M5L9rHtM93SagsoJ0P",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:26:32.733Z",
                    "internalTitle": "All training - Virtual training - Cards",
                    "title": "Sesiones de formación virtual en vivo",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Formación virtual en vivo sobre Dropbox | Formación de dropbox"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "Formación gratuita de Dropbox en línea con un instructor virtual en vivo. Obtén información práctica sobre Dropbox en un curso de aforo limitado. Regístrate en un curso hoy."
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "sesión de formación virtual",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": ".",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "completedCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Aquí aparecen los cursos que hayas completado.",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "inProgressCoursesEmptyState": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
          {
            "data": {},
            "marks": [],
            "value": "Aún no hay nada que ver. Comienza un ",
            "nodeType": "text"
          },
          {
            "data": {
              "target": {
                "_id": "75pzFP9abHFb2hlQycUS86",
                "_contentTypeId": "pageLanding",
                "_href": "/[slug]",
                "_as": "/self-guided-learning",
                "_modifiedDate": "2025-03-18T22:08:04.576Z",
                "internalTitle": "L2 - Landing page - Self-guided learnings",
                "name": "Aprendizaje autodirigido",
                "slug": "self-guided-learning",
                "introHero": {
                  "_id": "6pQlpoBglvzGW38DBWvU5W",
                  "_contentTypeId": "moduleHero",
                  "_modifiedDate": "2025-03-18T22:24:30.062Z",
                  "internalTitle": "Hero - Self-guided learning",
                  "headerText": "Aprendizaje autodirigido",
                  "summaryText": "Descubre cómo utilizar Dropbox con cursos diseñados para adaptarse a tu apretada agenda.\n",
                  "image": {
                    "title": "Ilustración de formación autodirigida",
                    "description": "Ilustración de libro para los cursos de formación autodirigida de Dropbox",
                    "url": "//images.ctfassets.net/zh1yxk5yn2r7/6ODZb6bllZrucIYtiLqFkU/f844e955cabc76e34e249d9c18e43d62/self-guided-learning.svg",
                    "size": 42902,
                    "width": 405,
                    "height": 310,
                    "filename": "self-guided-learning.svg",
                    "contentType": "image/svg+xml"
                  }
                },
                "contentSections": [
                  {
                    "_id": "6MGcQfM5kOFGpBqiOmSkKy",
                    "_contentTypeId": "moduleFeaturedCourse",
                    "_modifiedDate": "2025-03-18T22:24:36.327Z",
                    "internalTitle": "Featured - Self-guided learning",
                    "title": "Curso destacado",
                    "course": null
                  },
                  {
                    "_id": "4JIWsH681XIFek4zmBB6Ix",
                    "_contentTypeId": "moduleMultiColumnCourses",
                    "_modifiedDate": "2025-03-18T22:25:12.491Z",
                    "internalTitle": "All courses - Self-guided learning - Cards",
                    "title": "Todos los cursos",
                    "courses": []
                  }
                ],
                "seo": {
                  "title": {
                    "name": "title",
                    "value": "Cursos de formación autodirigidos de Dropbox | Formación de Dropbox"
                  },
                  "keywords": {
                    "name": "keywords",
                    "value": ""
                  },
                  "og:image": {
                    "name": "og:image",
                    "value": {
                      "id": "33cKbUcUgjCOB3aPxkIMGq",
                      "url": "https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png",
                      "title": "hoop"
                    }
                  },
                  "description": {
                    "name": "description",
                    "value": "Cursos de formación sobre Dropbox a tu ritmo, gratuitos y a demanda. Aprende a usar tu cuenta de Dropbox a tu propio ritmo. Regístrate hoy mismo."
                  },
                  "twitter:title": {
                    "name": "twitter:title",
                    "value": "Dropbox"
                  }
                }
              }
            },
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "curso de formación autodirigido",
                "nodeType": "text"
              }
            ],
            "nodeType": "entry-hyperlink"
          },
          {
            "data": {},
            "marks": [],
            "value": ".",
            "nodeType": "text"
          }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  },
  "l18nLookup": {
    "textHr": "h",
    "textHrs": "horas",
    "textMin": "mín.",
    "textHour": "hora",
    "textLess": "Menos",
    "textMins": "min",
    "textMore": "Más",
    "textRead": "Leer",
    "labelTags": "Etiquetas",
    "textClear": "Borrar",
    "textHours": "Horario",
    "textMinute": "minuto",
    "ctaTextBack": "Volver",
    "ctaTextNext": "Siguiente",
    "joinUrlText": "Unirse a sesión",
    "readingTime": "min de lectura",
    "textMinutes": "minutos",
    "textSeeMore": "Ver más",
    "textViewAll": "Ver todo",
    "searchHeader": "Buscar",
    "textComplete": "Completado",
    "textError404": "Error (404)",
    "textFilterBy": "Filtrar por",
    "ctaTextLogout": "Cerrar sesión",
    "ctaTextSignIn": "Inicia sesión",
    "labelSubjects": "Temas",
    "textNextTopic": "Siguiente módulo",
    "ctaTextProfile": "Perfil",
    "ctaTextVirtual": "Ver sesión",
    "headerDuration": "Duración",
    "languageNameDe": "Deutsch",
    "languageNameEs": "Español",
    "languageNameFr": "Français",
    "languageNameIt": "Italiano",
    "languageNameJa": "日本語",
    "ctaTextRegister": "Registrarte ahora",
    "labelCategories": "Categorías",
    "searchNoResults": "No hay resultados para {{search}}",
    "textStartCourse": "Empezar curso",
    "textWebinarFull": "Este seminario web está lleno. Inténtalo en otro horario o en otro día.",
    "accountLabelLast": "Apellidos",
    "accountLabelName": "Nombre",
    "ctaCreateAccount": "Crear cuenta",
    "languageNameEnUs": "English",
    "languageNamePtBr": "Português",
    "searchBreadcrumb": "Resultados de la búsqueda",
    "searchFilterRole": "Rol",
    "textErrorUnknown": "Se ha producido un error",
    "textResumeCourse": "Reanudar curso",
    "accountLabelEmail": "Correo electrónico",
    "ctaTextReschedule": "Reprogramar",
    "ctaTextSelfGuided": "Ver módulos",
    "ctaTextViewCourse": "Ver curso",
    "formLabelAttended": "Has asistido",
    "headerAccountInfo": "Información de cuenta",
    "textErrorPageHome": "Inicio",
    "textPreviousTopic": "Módulo anterior",
    "ctaTextCancelClass": "Cancelar esta clase",
    "formInputLabelDate": "Fecha",
    "headerPersonalInfo": "Información personal",
    "labelSubcategories": "Subcategorías",
    "searchResultStatus": "Results {{firstResult}}-{{lastResult}} of {{numResults}} for {{search}}",
    "textBreadcrumbRoot": "Inicio",
    "textCourseProgress": "Progreso del curso",
    "formInputLabelEmail": "Correo electrónico",
    "globalTextCopyright": "Texto de copyright",
    "textDropdownDefault": "Las horas mostradas usan tu zona horaria",
    "textErrorPageSignIn": "Inicia sesión",
    "textTableOfContents": "Módulos",
    "canceledRegistration": "Registro cancelado correctamente",
    "formInputLabelCourse": "Curso",
    "formInputLabelStatus": "Estado",
    "searchFilterLanguage": "Idioma",
    "searchResultsPerPage": "Resultados por página",
    "textSigninModalIntro": "Inicia sesión para guardar tu progreso o continúa avanzando",
    "topicCountTextPlural": "Temas",
    "topicCountTextSingle": "Tema",
    "headerSuggestedTopics": "Módulos sugeridos",
    "searchFilterOptionSgc": "Curso a tu propio ritmo",
    "searchResultTypeTopic": "Módulo",
    "searchResultTypeVideo": "Vídeo",
    "textAlreadyRegistered": "Ya te has registrado en este seminario web.",
    "textYouMustLogInFirst": "Primero debes iniciar sesión",
    "formInputLabelAttendee": "Asistente",
    "formInputLabelLastName": "Apellidos",
    "formInputLabelOverview": "Descripción general",
    "headerEventDescription": "Descripción de la sesión",
    "headerProfileCompleted": "Cursos completados",
    "searchFilterOptionLive": "Sesión en directo",
    "searchFilterOptionTeam": "Miembro del equipo",
    "textCourseExpectations": "Qué esperar:",
    "textLearningObjectives": "Lo que aprenderás:",
    "formInputLabelFirstName": "Nombre",
    "headerProfileInProgress": "Cursos en progreso",
    "pageVideoCategoriesSync": "Sincronización",
    "searchFilterContentType": "Tipo de contenido",
    "searchFilterOptionAdmin": "Administrador",
    "searchFilterOptionTopic": "Módulo",
    "searchPageTitleMetadata": "Buscar | Formación de Dropbox",
    "textErrorPageHelpCenter": "Centro de ayuda",
    "formInputLabelSelectDate": "Selecciona una fecha",
    "formInputLabelSelectTime": "Selecciona una hora",
    "headerProfileLiveCourses": "Próximos cursos",
    "pageVideoCategoriesShare": "Compartir",
    "formInputLabelSessionLink": "Enlace de la sesión",
    "textErrorPageHelpfulLinks": "Algunos enlaces de utilidad:",
    "textErrorPagePageNotFound": "No encontramos la página que estás buscando",
    "textRegistrationCompleted": "Te has registrado",
    "breadcrumbTextRegistration": "Registro",
    "formInputLabelPickLanguage": "Selecciona tu idioma",
    "pageVideoCategoriesWebinar": "Seminario web",
    "searchPageKeywordsMetadata": "búsqueda",
    "searchResultTypeSelfGuided": "Curso",
    "ctaTextCompleteRegistration": "Completa tu registro",
    "headerEnterRegistrationInfo": "Introduce tu información de registro",
    "headerRegisteredLiveCourses": "Formaciones virtuales en las que te has registrado",
    "pageVideoCategoriesInstalls": "Instalar",
    "pageVideoCategoriesOrganize": "Organizar",
    "pageVideoCategoriesProducts": "Productos",
    "pageVideoCategoriesSecurity": "Seguridad",
    "searchFilterOptionRoleAdmin": "Administrador",
    "searchResultTypeLiveSession": "Sesión en directo",
    "headerRegistrationSuccessful": "Te has registrado correctamente",
    "headerSearchInputPlaceholder": "Buscar",
    "pageVideoSubcategoriesDelete": "Eliminar",
    "pageVideoSubcategoriesReplay": "Replay",
    "pageVideoSubcategoriesRewind": "Rebobinar",
    "pageVideoSubcategoriesSignIn": "Inicia sesión",
    "searchFilterOptionIndividual": "Individual",
    "formInputLabelCourseCompleted": "Curso completado",
    "pageVideoSubcategoriesCapture": "Capture",
    "pageVideoSubcategoriesRestore": "Restaurar",
    "searchPageDescriptionMetadata": "Puedes buscar módulos o información para obtener más datos sobre nuestros cursos. Encuentra la formación o el curso autodirigido de Dropbox más adecuados para tus necesidades.",
    "pageVideoCategoriesViewAndEdit": "Ver y editar",
    "pageVideoSubcategoriesTransfer": "Transfer",
    "searchFilterOptionRoleHelpDesk": "Equipos de asistencia",
    "textCalendarShortWeekdayFriday": "Vie",
    "textCalendarShortWeekdayMonday": "Lun",
    "textCalendarShortWeekdaySunday": "Dom",
    "pageVideoSubcategoriesMobileApp": "Aplicación móvil",
    "textCalendarShortWeekdayTuesday": "Mar",
    "textEmailConfirmationWillBeSent": "Ve a tu bandeja de entrada y busca un mensaje de confirmación.",
    "headerLiveCourseHighlightsHeader": "Aprenderás a:",
    "pageVideoCategoriesAccountAccess": "Acceso a la cuenta",
    "pageVideoSubcategoriesTeamAccess": "Acceso del equipo",
    "searchFilterOptionRoleTeamMember": "Miembro del equipo",
    "textCalendarShortWeekdaySaturday": "Sáb",
    "textCalendarShortWeekdayThursday": "Jue",
    "formInputLabelGeneralRegistration": "Registro general",
    "pageVideoSubcategoriesTeamFolders": "Las carpetas de equipo",
    "textCalendarShortWeekdayWednesday": "Mié",
    "formInputLabelGlobalSelectLanguage": "Selecciona un idioma",
    "formInputLabelSelectDateBreadcrumb": "Seleccionar fecha",
    "pageVideoCategoriesAccountSettings": "Configuración de cuenta",
    "pageVideoCategoriesCreateAndUpload": "Crear y subir",
    "pageVideoSubcategoriesTeamSecurity": "Seguridad del equipo",
    "searchFilterOptionRoleFundamentals": "Aspectos básicos",
    "pageVideoCategoriesDeleteAndRestore": "Eliminar y restaurar",
    "pageCourseTopicTableOfContentsHeading": "Secciones en este artículo",
    "pageVideoSubcategoriesDropboxPolicies": "Políticas de Dropbox",
    "pageVideoSubcategoriesCommentingOnFiles": "Añadir comentarios en archivos",
    "pageVideoSubcategoriesSharingPermissions": "Permisos para compartir",
    "pageVideoSubcategoriesDropboxApplications": "Aplicaciones de Dropbox",
    "pageVideoSubcategoriesSearchingYourAccount": "Buscar en tu cuenta",
    "pageVideoSubcategoriesManagingAccountAccess": "Gestionar el acceso a la cuenta",
    "pageVideoSubcategoriesSharingFilesAndFolders": "Compartir archivos y carpetas",
    "pageVideoSubcategoriesCreatingFilesAndFolders": "Crear archivos y carpetas"
  },
  "copyrightText": "Dropbox 2021",
  "seo": {
    "title": {
      "name": "title",
      "value": "Dropbox learn"
    },
    "keywords": {
      "name": "keywords",
      "value": ""
    },
    "og:image": {
      "name": "og:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "og:title": {
      "name": "og:title",
      "value": "Dropbox learn"
    },
    "canonical": {
      "name": "canonical",
      "value": ""
    },
    "description": {
      "name": "description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:image": {
      "name": "twitter:image",
      "value": {
        "id": "2z2Foc0s6DPlQV1aTtWO3b",
        "url": "https://images.ctfassets.net/zh1yxk5yn2r7/2z2Foc0s6DPlQV1aTtWO3b/b0c0e0a58102dd90281f7b1df860a370/Dropbox.svg",
        "title": "Dropbox Logo"
      }
    },
    "twitter:title": {
      "name": "twitter:title",
      "value": "Dropbox learn"
    },
    "og:description": {
      "name": "og:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    },
    "twitter:description": {
      "name": "twitter:description",
      "value": "Learn how to use Dropbox. Browse our catalog of free self-guided courses and virtual instructor led training sessions. Start a Dropbox training course today."
    }
  },
  "seoOrganizationsSchema": {
    "url": "http://www.dropbox.com",
    "@type": "Organization",
    "@context": "http://schema.org",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": "TollFree"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "contactType": "customer service"
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": "US",
        "contactType": "customer service",
        "contactOption": [
          "HearingImpairedSupported",
          "TollFree"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "technical support",
        "contactOption": "TollFree",
        "availableLanguage": [
          "English",
          "French"
        ]
      },
      {
        "@type": "ContactPoint",
        "telephone": "+1-888-888-8888",
        "areaServed": [
          "US",
          "CA"
        ],
        "contactType": "bill payment",
        "contactOption": "TollFree"
      }
    ]
  }
}
  }
};

function getSettings(locale) {
  const obj = _.get(localeToSettingsMap, locale) || _.find(localeToSettingsMap, 'isDefault');
  return _.get(obj, 'settings');
}

export default getSettings;